import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import Button from 'product-ui/src/components/atoms/Button/Button';
import { AppState } from 'store/storeAccess';
import { Flex } from 'antd';
import { SavedFilterModel } from 'product-types/src/domain/savedFilters/SavedFilters';
import Typography from 'product-ui/src/components/atoms/Typography';
import { Badge } from 'product-ui/src/components/atoms/Badge/Badge';
import { useApplyFilter } from 'hooks/useApplyDefaultFilter';
import {
  FilterProviderContext,
  NewFilterProviderContext,
} from '../../../providers/NewFilterProvider/NewFilterProvider';
import { AddNewFilterModal } from './AddNewFilterModal';
import { StyledSelect } from './styled';
import { SavedFiltersProps } from '.';
import { EditFilterModal } from './EditFilterModal';
import { SavedFilterOption } from './SavedFilterOption';

export const SuggestionPortalSavedFilters = (props: SavedFiltersProps) => {
  const { savedFilters, filters } = useContext<NewFilterProviderContext>(
    FilterProviderContext,
  );
  const currentUser = useSelector(
    (state: AppState) => state.loginPage.currentUser,
  );
  const [search, setSearch] = useState('');
  const [applyFilter] = useApplyFilter();
  const [filterToEdit, setFilterToEdit] = useState<SavedFilterModel | null>(
    null,
  );
  const [showAddNewFilterModal, setShowAddNewFilterModal] = useState(false);

  const handleChangeFilter = useCallback(
    async (filter: SavedFilterModel) => {
      applyFilter(filter);
    },
    [props.value, savedFilters],
  );

  const options = useMemo(
    () => [
      ...(savedFilters ?? [])
        .filter((item) =>
          item.name.toLowerCase().includes(search.toLowerCase()),
        )
        .sort((a, b) => {
          if (a.isDefault && !b.isDefault) {
            return -1;
          }
          if (!a.isDefault && b.isDefault) {
            return 1;
          }
          return a.name.localeCompare(b.name);
        })
        .map((item) => ({
          value: item.id,
          label: item.name,
          data: item,
        })),
    ],
    [savedFilters, search],
  );

  const handleChangeDropdownVisible = useCallback(
    (open) => {
      props.setSavedFilterOpen(open || showAddNewFilterModal || !!filterToEdit);
    },
    [props, showAddNewFilterModal, filterToEdit],
  );

  useEffect(() => {
    if (!props.savedFilterOpen) {
      props.setSavedFilterOpen(showAddNewFilterModal || !!filterToEdit);
    }
  }, [showAddNewFilterModal, filterToEdit]);

  const dropdownRender = useCallback(
    () => (
      <Flex
        vertical
        style={{
          width: 196,
          padding: '4px',
          gap: '4px',
          maxHeight: '188px',
          borderRadius: '4px',
          overflowY: 'auto',
        }}
      >
        {options.map((item) => (
          <SavedFilterOption
            key={item.value}
            item={item.data}
            applyFilter={handleChangeFilter}
            setFilterToEdit={setFilterToEdit}
          />
        ))}
      </Flex>
    ),
    [options],
  );

  const defaultIsSelected = useMemo(
    () =>
      savedFilters?.find((filter) => filter.isDefault)?.id ===
      filters?.currentFilterIdApplied,
    [filters, savedFilters],
  );

  const defaultFilter = useMemo(
    () => savedFilters?.find((filter) => filter.isDefault),
    [filters, savedFilters],
  );

  const placeholder = useMemo(() => {
    let currentFilterName: undefined | SavedFilterModel;
    if (filters?.currentFilterIdApplied) {
      currentFilterName = savedFilters?.find(
        (filter) => filter.id === filters.currentFilterIdApplied,
      );
    }
    return (
      <Flex
        align="center"
        justify="space-between"
        style={{
          maxWidth: '158px',
          overflow: 'hidden',
        }}
        gap={8}
      >
        <Typography
          variant="small"
          color="var(--primary-black)"
          fontWeight="medium"
          style={{
            textOverflow: 'ellipsis',
            flexGrow: 1,
            color: 'var(--primary-black)',
            flexShrink: 1,
            overflow: 'hidden',
          }}
        >
          {currentFilterName?.name ?? 'Select ...'}
        </Typography>
        {currentFilterName?.isDefault && (
          <Badge
            color="green-tints-green-2"
            style={{
              minWidth: 32,
              color: 'var(--primary-black)',
              fontWeight: 700,
              flexShrink: 0,
            }}
          >
            <Typography variant="xxsmall" fontWeight="medium">
              Default
            </Typography>
          </Badge>
        )}
      </Flex>
    );
  }, [defaultIsSelected, defaultFilter, savedFilters, filters]);

  return (
    <div
      id="suggestionPortal"
      style={{
        width: 196,
        minHeight: 80,
      }}
    >
      <Flex vertical gap="0.5rem" align="stretch">
        <StyledSelect
          showSearch
          onSearch={setSearch}
          searchValue={search}
          filterOption={false}
          onDropdownVisibleChange={handleChangeDropdownVisible}
          size="large"
          dropdownRender={dropdownRender}
          placeholder={placeholder}
          value={null}
        />
        {currentUser?.data?.role?.canModifyFilters && (
          <Button
            label="SAVE NEW FILTER"
            variant="Secondary"
            size="Medium"
            style={{
              padding: '16px 22px',
            }}
            onClick={() => {
              setShowAddNewFilterModal(true);
            }}
          />
        )}
        <AddNewFilterModal
          setShowAddNewFilterModal={setShowAddNewFilterModal}
          showAddNewFilterModal={showAddNewFilterModal}
        />
        <EditFilterModal
          filterToEdit={filterToEdit}
          setFilterToEdit={setFilterToEdit}
        />
      </Flex>
    </div>
  );
};
