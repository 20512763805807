import { Flex, Tooltip } from 'antd';
import { SavedFilterModel } from 'product-types/src/domain/savedFilters/SavedFilters';
import { Badge } from 'product-ui/src/components/atoms/Badge/Badge';
import NaveeIcon from 'product-ui/src/components/atoms/NaveeIcon/NaveeIcon';
import Typography from 'product-ui/src/components/atoms/Typography';
import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'store/storeAccess';
import styled from 'styled-components';

interface SavedFilterOptionProps {
  setFilterToEdit: (filter: SavedFilterModel) => void;
  applyFilter: (filter: SavedFilterModel) => void;
  item: SavedFilterModel;
}

const Container = styled(Flex)`
  position: relative;
  height: 44px;
  padding: 12px 16px;
  &:hover {
    background-color: var(--primary-hover);
    & .edit-filter {
      display: block;
    }
  }
  & .edit-filter {
    flex-shrink: 0;
    display: none;
  }
`;

export const SavedFilterOption = ({
  setFilterToEdit,
  applyFilter,
  item,
}: SavedFilterOptionProps) => {
  const currentUser = useSelector(
    (state: AppState) => state.loginPage.currentUser,
  );
  return (
    <Tooltip title={item.name} trigger="hover">
      <Container
        justify="space-between"
        align="center"
        gap="0.25rem"
        style={{
          position: 'relative',
          height: '44px',
          padding: '12px 16px',
        }}
        onClick={() => applyFilter(item)}
      >
        <Typography
          variant="small"
          fontWeight="regular"
          style={{
            flexShrink: 10,
            maxWidth: 'calc(100% - 1rem - 0.25rem )',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          {item.name}
        </Typography>
        {item.isDefault && (
          <Badge
            size="small"
            color="green-tints-green-2"
            style={{
              whiteSpace: 'nowrap',
            }}
          >
            <Typography variant="xxsmall" fontWeight="medium">
              Default
            </Typography>
          </Badge>
        )}
        {currentUser?.data?.role?.canModifyFilters && (
          <NaveeIcon.Pencil
            className="edit-filter"
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              setFilterToEdit(item);
              return false;
            }}
          />
        )}
      </Container>
    </Tooltip>
  );
};
