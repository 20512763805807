import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { SelectProps } from 'antd/es/select';
import { debounce } from 'product-utils/src/debounce';
import { Select } from '../Select';
type Option = { label: string; value: string };

interface Props
  extends Omit<
    SelectProps,
    | 'showSearch'
    | 'allowClear'
    | 'options'
    | 'filterOption'
    | 'defaultActiveFirstOption'
    | 'notFoundContent'
  > {
  loadOptions: (search: string) => Promise<Array<Option>>;
  hint?: ReactNode;
  debounceTime?: number;
  minAllowedTextLength?: number;
  notFoundContent?: ((search: string) => ReactNode) | ReactNode;
  removeInsertedFromOptions?: boolean;
}

export const AsyncSelect = (props: Props) => {
  const [search, setSearch] = useState('');
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [options, setOptions] = useState<Array<Option>>([]);
  const [debounced, setDebounced] = useState<{ cancel?: () => void } | null>(
    null,
  );
  const {
    minAllowedTextLength = 4,
    loadOptions: searchPromise,
    hint = null,
    debounceTime = 700,
    removeInsertedFromOptions = false,
    ...rest
  } = props;

  const debounceSearch = useCallback(
    debounce(
      (q: string) =>
        searchPromise(q)
          .then(setOptions)
          .finally(() => setLoadingOptions(false)),
      debounceTime ?? 700,
    ),
    [rest.value],
  );

  useEffect(() => () => {
    if (debounced?.cancel) {
      debounced.cancel();
    }
  });

  useEffect(() => {
    const normalizedSearch = search.trim();
    if (normalizedSearch.length > 0) {
      setOptions([]);
    }
    if (normalizedSearch.length >= minAllowedTextLength) {
      setLoadingOptions(true);
      setDebounced(debounceSearch(normalizedSearch));
    }
  }, [search]);

  let notFoundContent: ReactNode | null = null;
  if (loadingOptions) {
    notFoundContent = 'Loading...';
  } else {
    if (search.length > 0 && search.length < minAllowedTextLength) {
      notFoundContent = hint;
    }
    if (options.length === 0 && search.length >= minAllowedTextLength) {
      notFoundContent = props.notFoundContent;
      if (typeof props.notFoundContent === 'function') {
        notFoundContent = props.notFoundContent(search);
      }
    }
  }

  const filteredOptions = useMemo(
    () =>
      (removeInsertedFromOptions
        ? options.filter(
            (opt) =>
              !(rest.value ?? [])
                .map((o) => o[rest.fieldNames?.value ?? 'value'])
                .includes(opt[rest.fieldNames?.value ?? 'value']),
          )
        : options
      ).map((opt) => {
        if (rest.fieldNames?.value) {
          return { ...opt, value: opt[rest.fieldNames.value] };
        }
        return opt;
      }),
    [removeInsertedFromOptions, options, rest.value],
  );

  const handleChange = (value) => {
    if (rest.onChange) {
      rest.onChange(
        value,
        options.filter((o) =>
          value.includes(o[rest.fieldNames?.value ?? 'value']),
        ),
      );
    }
  };

  return (
    <Select
      allowClear
      {...rest}
      showSearch
      defaultActiveFirstOption={false}
      filterOption={false}
      options={filteredOptions}
      notFoundContent={notFoundContent}
      onSearch={(query) => {
        setSearch(query);
        if (rest.onSearch) {
          rest.onSearch(query);
        }
      }}
      onChange={handleChange}
    />
  );
};
