import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './app.css';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';
import Gleap from 'gleap';
import { deselectElements } from 'layout/FeedFooter/actions';
import { useBeforeUnload } from 'hooks/useBeforeUnload';
import { GlobalStyle } from '../../global-styles';
import 'react-image-lightbox/style.css';
import {
  useLoadSystemWideResources,
  useRefreshFiltersOnNavigation,
} from './hooks';
import Router from './Router';
import useSharedWorker from './hooks/useSharedWorker';
import { useInjectRedux } from './hooks/injectRedux';


const RenderApp = () => {
  const url = new URL(window.location.href);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(deselectElements());
    notification.destroy();
  }, [url.pathname.replace('/', '')]);
  useEffect(() => {
    document.querySelector('.lds-spinner-container')?.remove();
  }, []);

  return (
    <>
      <Router key="router" />
      <GlobalStyle key="global-styles" />
    </>
  );
};

export function App() {
  const mounted = useInjectRedux();
  useLoadSystemWideResources();
  useRefreshFiltersOnNavigation();
  const location = useLocation();
  useBeforeUnload();

  useSharedWorker(location);
  useEffect(() => {
    if (location.pathname === '/login') {
      Gleap.showFeedbackButton(false);
    } else {
      Gleap.showFeedbackButton(true);
    }
  }, [location.pathname]);

  if (!mounted) {
    return null;
  }

  return <RenderApp />;
}

export default App;
