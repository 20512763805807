import React from 'react';
import {
  DefaultStylesForVariant,
  DefaultTagForVariant,
  FontWeight,
} from '../../../../src/components/atoms/Typography/TypographyProps';
import { HyperLinkProps, Target } from './HyperLinkProps';
import NaveeIcon from '../NaveeIcon/NaveeIcon';

export default function Link({
  children,
  letterSpacing = 'initial',
  textTransform,
  color,
  disabled,
  passEventsDuringDisabled = false,
  className = '',
  fontWeight,
  hidden,
  variant = 'medium',
  opacity = 1,
  textDecoration,
  style,
  onClick,
  onMouseLeave,
  onPointerEnter,
  onPointerLeave,
  onMouseOver,
  tag = 'a',
  href,
  preIcon,
  postIcon,
  target = Target.blank,
}: HyperLinkProps) {
  return React.createElement(
    tag || DefaultTagForVariant[variant],
    {
      className,
      href: disabled ? undefined : href,
      disabled,
      target: disabled ? undefined : target,
      hidden,
      onClick: disabled && !passEventsDuringDisabled ? undefined : onClick,
      onPointerEnter:
        disabled && !passEventsDuringDisabled ? undefined : onPointerEnter,
      onPointerLeave:
        disabled && !passEventsDuringDisabled ? undefined : onPointerLeave,
      onMouseLeave:
        disabled && !passEventsDuringDisabled ? undefined : onMouseLeave,
      onMouseOver:
        disabled && !passEventsDuringDisabled ? undefined : onMouseOver,
      style: {
        fontFamily: 'Satoshi',
        opacity,
        color: disabled ? 'rgba(0, 0, 0, 0.25)' : color,
        '--current-link-color': disabled ? 'rgba(0, 0, 0, 0.25)' : color,
        fontWeight:
          typeof fontWeight === 'number'
            ? fontWeight
            : FontWeight[fontWeight ?? 'medium'],
        cursor: disabled ? 'not-allowed' : 'pointer',
        letterSpacing,
        textDecoration,
        textTransform,
        width: 'fit-content',
        display: 'flex',
        alignItems: 'center',
        gap: '0.5em',
        ...DefaultStylesForVariant[variant],
        ...style,
      },
    },
    [preIcon, children, postIcon],
  );
}

export const DefaultLinkIcon = React.memo(() => (
  <NaveeIcon.BlueLink key="blueLinkIcon" />
));
