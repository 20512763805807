/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React, { ReactElement, useState } from 'react';
import { Tooltip } from 'antd';
import { TagModel } from 'product-types/src/domain/tag/Tag';
import { Spin } from '../Spin/Spin';
import NaveeIcon from '../NaveeIcon/NaveeIcon';
import { Value } from './value';
import Typography from '../Typography';
import { useGetClassesFromObject } from '../Typography/hooks';
import { Badge } from '../Badge/Badge';

// todo: refactor tags
interface TagProp {
  tag: TagModel;
  onClickRemove?: (any) => void;
  onClick?: (any) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onPointerEnter?: () => void;
  onPointerLeave?: () => void;
  showRemoveIcon?: boolean;
  excluded?: boolean;
  variant?: TagVariant | keyof typeof TagVariant;
  iconPrefix?: ReactElement;
  disableLoading?: boolean;
  label?: ReactElement;
  disableTooltip?: boolean;
  allTagCursorPointer?: boolean;
  tooltipText?: string;
  count?: number;
  countVariant?: string;
  style?: React.CSSProperties;
  maxWidth?: string;
  tagTooltipContainer?: () => HTMLElement;
  tooltipZIndex?: number;
}

export enum TagVariant {
  default = 'default',
  outlined = 'outlined',
  excluded = 'excluded',
}

export default React.forwardRef(
  (
    {
      tag,
      variant = TagVariant.default,
      onClickRemove = () => {},
      onClick,
      onMouseEnter,
      allTagCursorPointer,
      onMouseLeave,
      disableLoading,
      onPointerEnter,
      onPointerLeave,
      showRemoveIcon = true,
      excluded = false,
      tooltipText,
      iconPrefix,
      label,
      count,
      countVariant,
      disableTooltip = false,
      maxWidth,
      style,
      tagTooltipContainer,
      tooltipZIndex,
    }: TagProp,
    ref: any,
  ) => {
    const [loading, setLoading] = useState(false);
    const tooltipPlacement = tag.name.length > 500 ? 'right' : 'top';
    const classes = useGetClassesFromObject({
      tag: !tag.__isNew__,
      'new tag': tag.__isNew__,
      excluded,
      [variant]: true,
    });
    if (!tag) {
      return null;
    }
    return (
      <div
        ref={ref}
        key={tag.id}
        onMouseEnter={onMouseEnter}
        onClick={onClick}
        style={{
          cursor: allTagCursorPointer ? 'pointer' : 'default',
          ...(style || {}),
        }}
        onMouseLeave={onMouseLeave}
        onPointerEnter={onPointerEnter}
        onPointerLeave={onPointerLeave}
      >
        <Spin spinning={!disableLoading && loading} size="small">
          <Tooltip
            destroyTooltipOnHide
            open={disableTooltip ? false : undefined}
            placement={tooltipPlacement}
            title={tooltipText?.length ? tooltipText : tag.name}
            getPopupContainer={tagTooltipContainer}
            zIndex={tooltipZIndex}
          >
            <Value className={classes()} style={{ maxWidth }}>
              {iconPrefix}
              {label ?? (
                <Typography
                  dataTestId="label-tag"
                  variant="small"
                  color="var(--neutral-grey-800)"
                  style={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  {tag.name}
                </Typography>
              )}
              {count && (
                <Badge size="small" total={count} color={countVariant} />
              )}
              {showRemoveIcon && (
                <NaveeIcon.Cross
                  style={{ cursor: 'pointer' }}
                  className="close"
                  height={10}
                  width={10}
                  onClick={(e) => {
                    e?.preventDefault?.();
                    setLoading(true);
                    onClickRemove(tag.id);
                  }}
                />
              )}
            </Value>
          </Tooltip>
        </Spin>
      </div>
    );
  },
);
