import React from 'react';
import { notification } from 'antd';
import { goBack, push } from 'connected-react-router';
import queryString from 'query-string';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import {
  CommentModel,
  CommentRaw,
} from 'product-types/src/domain/comment/Comment';
import {
  WebsiteModerationModel,
  WebsiteModerationRawModel,
} from 'product-types/src/domain/website/WebsiteModerationModel';
import { WebsiteFeedModel } from 'product-types/src/domain/website/WebsiteFeedModel';
import { Image } from 'product-types/src/domain/Domain';

import { RelatedWebsite } from 'product-types/src/domain/website/RelatedWebsite';
import { TableParams } from 'product-types/src/common/TableParams/TableParams';
import { CustomErrorFactory } from 'product-types/src/common/Error/CustomError';
import {
  AssociatedImagesWebsite,
  WebsiteFeedDataModel,
} from 'product-types/src/common/FeedGeneric/FeedGeneric';
import {
  FetchableData,
  FetchableDataState,
} from 'product-types/src/common/FetchableData/FetchableData';
import NaveeIcon from 'product-ui/src/components/atoms/NaveeIcon/NaveeIcon';
import { SelectedElementTypeEnum } from 'layout/FeedFooter/reducer';
import { SELECT_FILTER } from 'layout/FiltersBar/constants';
import { setShowAllPostsReservedAction } from 'layout/FiltersBar/actions';
import { removeEmptyProperties } from 'product-utils/src/object';
import { Contact } from 'product-types/src/domain/contact/Contact';
import { buildALinkWithOrgId } from '../../hooks/useNavigation';
import { LoginPageState } from '../LoginPage/reducer';
import makeSelectLoginPage from '../LoginPage/selectors';
import Network from '../../types/network';
import { RelatedWebsitesResponse } from '../../types/network/Http/productMonitor/endpoints/websites/relatedWebsites';
import { WebsiteFeedResponseModel } from '../../types/network/Http/productMonitor/endpoints/websites/getWebsites';
import {
  loadWebsiteImagesAction,
  updateWebsiteImages,
  loadWebsiteResourcesAction,
  loadWebsitesDataSuccessAction,
  updateWebsite,
  updateWebsiteCommentAction,
  updateWebsiteModerationQueue,
  updateRelatedWebsites,
  updateRelatedWebsitesTotal,
  refreshWebsiteAction,
  loadWebsiteComments,
} from './actions';
import { selectFilterAction } from '../DashboardPage/actions';

import {
  LOAD_WEBSITES_DATA,
  LOAD_IMAGES_WEBSITE,
  LOAD_NEXT_WEBSITES_TO_MODERATE,
  LOAD_PREV_WEBSITES_TO_MODERATE,
  PERFORM_WEBSITE_MODERATION,
  REFRESH_WEBSITE,
  SET_GEO_WEBSITE,
  DEL_GEO_WEBSITE,
  LOAD_COMMENTS_WEBSITES,
  LOAD_RELATED_WEBSITES,
  MODERATE_WEBSITE_AND_UPDATE_IN_PLACE,
  LOAD_WEBSITE_RESOURCES,
  LOAD_RELATED_POSTS_WEBSITE_SUCCESS,
  RECRAWL_WEBSITE,
  ADD_COMMENT_WEBSITE,
  DELETE_COMMENT_WEBSITE,
  UPDATE_CONTACT_INFORMATION_WEBSITE,
} from './constants';
import ProductMonitor from '../../types/network/Http/productMonitor';
import { FeedRequestParameter } from '../../types/network/Feed/Feed';
import { AppState } from '../../store/storeAccess';
import { loadPostsDataAction } from '../PostViewContainer/actions';

export function* loadNextWebsiteToModerate(action) {
  yield put(
    updateWebsite(
      new FetchableData<WebsiteModerationModel>({
        abortController: null,
        state: FetchableDataState.LOADING,
        data: null,
        error: null,
      }),
    ),
  );
  yield put(selectFilterAction('isModeration', true, SELECT_FILTER));

  let fn =
    ProductMonitor.endpoints.websites.websitesModeration.nextWebsiteToModerate.call.bind(
      ProductMonitor.endpoints.websites.websitesModeration
        .nextWebsiteToModerate,
    );
  if (action.type === LOAD_PREV_WEBSITES_TO_MODERATE) {
    fn =
      ProductMonitor.endpoints.websites.websitesModeration.prevWebsiteToModerate.call.bind(
        ProductMonitor.endpoints.websites.websitesModeration
          .prevWebsiteToModerate,
      );
  }

  const { skipNavigation = false, ...data } = action.state;
  try {
    const website_moderation_data: {
      account: WebsiteModerationRawModel;
      account_id: string;
      account_moderation_index: number;
      number_of_accounts_to_moderate: number;
    } = yield call(fn, {
      data: removeEmptyProperties(data),
    });

    const website = WebsiteModerationModel.createFromRawModel(
      website_moderation_data.account,
    );

    const loginPage: LoginPageState = yield select(makeSelectLoginPage());
    if (!loginPage.currentUser?.data?.organisation?.uid) {
      throw new Error('No organisation id');
    }

    if (!skipNavigation) {
      if (action.type === LOAD_PREV_WEBSITES_TO_MODERATE) {
        yield put(goBack());
      } else {
        yield put(
          push(
            buildALinkWithOrgId(
              loginPage.currentUser?.data?.organisation?.uid,
              `${website.linkToModerationPage}/moderation`,
            ),
          ),
        );
      }
    }

    yield put(
      updateWebsite(
        new FetchableData<WebsiteModerationModel>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: website,
          error: null,
        }),
      ),
    );

    yield put(
      updateWebsiteModerationQueue({
        website_moderation_index:
          website_moderation_data.account_moderation_index,
        number_of_websites_to_moderate:
          website_moderation_data.number_of_accounts_to_moderate,
      }),
    );
    yield call(loadWebsiteResources, {
      skipWebsiteCall: true,
      websiteId: website._id,
      type: '',
    });
  } catch (error) {
    yield put(selectFilterAction('isModeration', false, SELECT_FILTER));
    // Detect whether the error comes from a 404
    if (error?.response?.status === 404) {
      // Dispatch something to display a message (modal)
      yield put(setShowAllPostsReservedAction(true));
      yield put(
        updateWebsite(
          new FetchableData<WebsiteModerationModel>({
            abortController: null,
            state: FetchableDataState.ERROR,
            data: null,
            error: CustomErrorFactory.create(error),
          }),
        ),
      );
    } else {
      yield put(
        setShowAllPostsReservedAction(
          true,
          `Something went wrong while loading websites to moderate: ${error.message}`,
          'Error starting moderation',
        ),
      );
      yield put(
        updateWebsite(
          new FetchableData<WebsiteModerationModel>({
            abortController: null,
            state: FetchableDataState.ERROR,
            data: null,
            error: CustomErrorFactory.create(error),
          }),
        ),
      );
    }
  }
}

function* recrawlWebsite() {
  const website: WebsiteModerationModel = yield select(
    (st: AppState) => st.websiteViewState?.websiteModerationData?.website?.data,
  );
  try {
    yield call(
      ProductMonitor.endpoints.upload.uploadAccount.call.bind(
        ProductMonitor.endpoints.upload.uploadAccount,
      ),
      {
        data: {
          global_label: '',
          global_tags: [],
          global_vendor: '',
          override: null,
          accounts: [
            {
              url: website.externalLink,
              label: website?.moderation?.moderation ?? '',
              tags: [],
            },
          ],
        },
      },
    );
  } catch (err) {
    console.error(err);
  } finally {
    yield put(refreshWebsiteAction({ website_id: website?._id }));
  }
}

function* addCommentWebsite(action: { [key: string]: any; objectId: string }) {
  const comments = yield select(
    (st: AppState) => st.websiteViewState?.comments?.data,
  );
  yield put(
    updateWebsiteCommentAction(
      new FetchableData<Array<CommentModel>>({
        abortController: null,
        state: FetchableDataState.LOADING,
        data: comments,
        error: null,
      }),
    ),
  );
  try {
    yield call(
      ProductMonitor.endpoints.accounts.comments.add.call.bind(
        ProductMonitor.endpoints.accounts.comments.add,
      ),
      {
        urlParams: { objectId: action.objectId },
        data: action.data,
      },
    );
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  } finally {
    yield put(loadWebsiteComments(action.objectId));
  }
}
function* deleteCommentWebsite(action: {
  [key: string]: any;
  objectId: string;
}) {
  const comments = yield select(
    (st: AppState) => st.websiteViewState?.comments?.data,
  );
  yield put(
    updateWebsiteCommentAction(
      new FetchableData<Array<CommentModel>>({
        abortController: null,
        state: FetchableDataState.LOADING,
        data: comments,
        error: null,
      }),
    ),
  );
  try {
    yield call(
      ProductMonitor.endpoints.accounts.comments.delete.call.bind(
        ProductMonitor.endpoints.accounts.comments.delete,
      ),
      {
        urlParams: { objectId: action.objectId, commentId: action.commentId },
      },
    );
    yield delay(200);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  } finally {
    yield put(loadWebsiteComments(action.objectId));
  }
}

function* loadCommentsSaga(action) {
  yield put(
    updateWebsiteCommentAction(
      new FetchableData<Array<CommentModel>>({
        abortController: null,
        state: FetchableDataState.LOADING,
        data: null,
        error: null,
      }),
    ),
  );
  try {
    const comments: { comments: Array<CommentRaw> } = yield call(
      ProductMonitor.endpoints.websites.comments.get.call.bind(
        ProductMonitor.endpoints.websites.comments.get,
      ),
      {
        urlParams: { id: action.websiteId },
      },
    );
    yield put(
      updateWebsiteCommentAction(
        new FetchableData<Array<CommentModel>>({
          abortController: null,
          state: FetchableDataState.ERROR,
          data: comments.comments.map(CommentModel.createFromRawModel),
          error: null,
        }),
      ),
    );
  } catch (err) {
    yield put(
      updateWebsiteCommentAction(
        new FetchableData<Array<CommentModel>>({
          abortController: null,
          state: FetchableDataState.ERROR,
          data: null,
          error: (err as any).message,
        }),
      ),
    );
  }
}

function* refreshWebsite(action) {
  yield put(
    updateWebsite(
      new FetchableData<WebsiteModerationModel>({
        abortController: null,
        state: FetchableDataState.LOADING,
        data: null,
        error: null,
      }),
    ),
  );
  const fn = ProductMonitor.endpoints.websites.getModerationWebsite.call.bind(
    ProductMonitor.endpoints.websites.getModerationWebsite,
  );
  try {
    const website: WebsiteModerationRawModel = yield call(fn, {
      urlParams: { id: action.website_id },
    });
    yield put(
      updateWebsite(
        new FetchableData<WebsiteModerationModel>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: WebsiteModerationModel.createFromRawModel(website),
          error: null,
        }),
      ),
    );
  } catch (err) {
    if (err?.response?.status === 404) {
      yield put(push('/notFound'));
    } else {
      yield put(
        updateWebsite(
          new FetchableData<WebsiteModerationModel>({
            abortController: null,
            state: FetchableDataState.ERROR,
            data: null,
            error: CustomErrorFactory.create(err),
          }),
        ),
      );
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }
}

function* loadWebsiteResources(
  action: ReturnType<typeof loadWebsiteResourcesAction> & {
    skipWebsiteCall?: boolean;
  },
) {
  if (!action.skipWebsiteCall) {
    yield call(refreshWebsite, { website_id: action.websiteId.toString() });
  }

  yield put(
    loadWebsiteImagesAction({
      object_id: action.websiteId,
      offset: 0,
      perpage: 10,
    }),
  );

  const relatedWebsites = yield select(
    (st: AppState) =>
      st.websiteViewState?.websiteModerationData.relatedWebsites,
  );

  yield call(loadRelatedWebsites, {
    website_id: action.websiteId,
    filters: relatedWebsites.filters,
  });

  const { type, selectedElementsIds } = yield select(
    (st: AppState) => st.footer_state,
  );
  const relatedPosts = yield select(
    (st: AppState) => st.websiteViewState?.relatedPosts,
  );

  yield put(
    loadPostsDataAction({
      pageState: {
        website_id: [{ id: action.websiteId.slice(3) }],
        isAccountViewRelatedPosts: true,
        duplicated_group_id:
          (type === SelectedElementTypeEnum.Image && [
            ...selectedElementsIds.keys(),
          ]) ||
          null,
        ...relatedPosts.filters.queryFilterValue,
      },
      successAction: LOAD_RELATED_POSTS_WEBSITE_SUCCESS,
    }),
  );

  yield call(loadCommentsSaga, { websiteId: action.websiteId });
}

function* setGeoforWebsite(action) {
  try {
    const myCountry = { country: action.value };

    yield call(
      ProductMonitor.endpoints.me.addGeography.call.bind(
        ProductMonitor.endpoints.me.addGeography,
      ),
      { data: myCountry, urlParams: { id: action.website_id } },
    );
  } catch (err) {
    console.error(err);
  } finally {
    yield put(action.refresh_action);
  }
}

function* delGeoforWebsite(action) {
  try {
    yield call(
      ProductMonitor.endpoints.me.deleteGeography.call.bind(
        ProductMonitor.endpoints.me.deleteGeography,
      ),
      {
        urlParams: { id: action.website_id },
      },
    );
  } catch (err) {
    console.error(err);
  } finally {
    yield put(action.refresh_action);
  }
}

function* loadImagesSaga(action) {
  yield put(
    updateWebsiteImages(
      new FetchableData<AssociatedImagesWebsite>({
        abortController: null,
        state: FetchableDataState.LOADING,
        data: null,
        error: null,
      }),
    ),
  );
  const { object_id, offset = 0, perpage = 10 } = action;
  try {
    const website_images = yield call(
      ProductMonitor.endpoints.images.getImages.call.bind(
        ProductMonitor.endpoints.images.getImages,
      ),
      {
        data: {
          account_website_id: object_id.slice(3),
          offset,
          sort_by: 'occurencies',
          reverse: true,
          perpage,
        } as FeedRequestParameter,
      },
    );
    yield put(
      updateWebsiteImages(
        new FetchableData<AssociatedImagesWebsite>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: {
            images: website_images.duplicated_groups.map(
              Image.ImageFeedModel.ImageFeedModel.createFromImageFeedRawModel,
            ),
            total: website_images.total,
          },
          error: null,
        }),
      ),
    );
  } catch (err) {
    yield put(
      updateWebsiteImages(
        new FetchableData<AssociatedImagesWebsite>({
          abortController: null,
          state: FetchableDataState.ERROR,
          data: null,
          error: CustomErrorFactory.create(err),
        }),
      ),
    );
  }
}

function* performWebsiteModeration(action) {
  const appState: AppState = yield select((state) => state);
  const websitesBeforeModeration = appState.websiteViewState.websitesData.data;
  yield put(
    loadWebsitesDataSuccessAction(
      new FetchableData<WebsiteFeedDataModel>({
        abortController: null,
        state: FetchableDataState.LOADING,
        data: websitesBeforeModeration,
        error: null,
      }),
    ),
  );
  try {
    const res = yield call(
      ProductMonitor.endpoints.websites.websitesModeration.moderateWebsite.call.bind(
        ProductMonitor.endpoints.websites.websitesModeration.moderateWebsite,
      ),
      { data: action.data },
    );
    if (res.message) {
      notification.info({
        message: res.message,
        placement: 'bottomRight',
        icon: (
          <NaveeIcon.CheckGreen
            style={{ color: 'var(--custom-green)', fontSize: 16 }}
          />
        ),
        duration: 5,
      });
    } else if (action.success_message) {
      notification.info({
        message: action.success_message,
        placement: 'bottomRight',
        icon: (
          <NaveeIcon.CheckGreen
            style={{ color: 'var(--custom-green)', fontSize: 16 }}
          />
        ),
        duration: 5,
      });
    }
  } catch (err) {
    console.error(err);
  } finally {
    yield put(
      loadWebsitesDataSuccessAction(
        new FetchableData<WebsiteFeedDataModel>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: websitesBeforeModeration,
          error: null,
        }),
      ),
    );
    // todo: rewrite code
    if (typeof action.refresh_action === 'object') {
      yield put(action.refresh_action);
    }
    if (typeof action.refresh_action === 'function') {
      action.refresh_action();
    }
  }
}

function* moderateWebsiteAndUpdateInPlace(action) {
  const appState: AppState = yield select((state) => state);
  const websiteBeforeModeration =
    appState.websiteViewState.websiteModerationData.website.data;

  try {
    yield put(
      updateWebsite(
        new FetchableData<WebsiteModerationModel>({
          abortController: null,
          state: FetchableDataState.LOADING,
          data: null,
          error: null,
        }),
      ),
    );

    const res = yield call(
      ProductMonitor.endpoints.websites.websitesModeration.moderateAndReturnWebsite.call.bind(
        ProductMonitor.endpoints.websites.websitesModeration
          .moderateAndReturnWebsite,
      ),
      { data: action.data },
    );

    yield put(
      updateWebsite(
        new FetchableData<WebsiteModerationModel>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: WebsiteModerationModel.createFromRawModel(res),
          error: null,
        }),
      ),
    );

    if (action.success_message) {
      notification.info({
        message: action.success_message,
        placement: 'bottomRight',
        icon: (
          <NaveeIcon.CheckGreen
            style={{ color: 'var(--custom-green)', fontSize: 16 }}
          />
        ),
        duration: 5,
      });
    }
  } catch (err) {
    yield put(
      updateWebsite(
        new FetchableData<WebsiteModerationModel>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: websiteBeforeModeration,
          error: null,
        }),
      ),
    );

    notification.error({
      message: 'Something went wrong while moderating the website',
      description: CustomErrorFactory.create(err).message,
      placement: 'bottomRight',
      duration: 15,
    });

    // eslint-disable-next-line no-console
    console.error(err);
  }
}

function* loadWebsitesDataSaga(action) {
  const requestParameters = Network.Website.getRequestParameter(action.payload);

  yield put(
    push({
      search: `${queryString.stringify(requestParameters)}`,
    }),
  );
  yield put(
    loadWebsitesDataSuccessAction(
      new FetchableData<WebsiteFeedDataModel>({
        abortController: action.payload.abortController,
        state: FetchableDataState.LOADING,
        data: null,
        error: null,
      }),
    ),
  );

  try {
    const websitesData: WebsiteFeedResponseModel = yield call(
      ProductMonitor.endpoints.websites.getWebsites.call.bind(
        ProductMonitor.endpoints.websites.getWebsites,
      ),
      {
        data: requestParameters,
        signal: action.payload.abortController?.signal,
        suppressToastrOnError: true,
      },
    );

    yield put(
      loadWebsitesDataSuccessAction(
        new FetchableData<WebsiteFeedDataModel>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: {
            websites: websitesData.accounts.map(
              WebsiteFeedModel.createFromWebsiteFeedRawModel,
            ),
            currency_iso: websitesData.currency_iso,
            total: websitesData.total,
          },
          error: null,
        }),
      ),
    );
  } catch (err) {
    yield put(
      loadWebsitesDataSuccessAction(
        new FetchableData<WebsiteFeedDataModel>({
          abortController: null,
          state: FetchableDataState.ERROR,
          data: null,
          error: CustomErrorFactory.create(err),
        }),
      ),
    );
  }
}

function* loadRelatedWebsites(action) {
  yield put(
    updateRelatedWebsites(
      new FetchableData<Array<RelatedWebsite>>({
        abortController: null,
        state: FetchableDataState.LOADING,
        data: null,
        error: null,
      }),
    ),
  );
  try {
    const responseWebsites: RelatedWebsitesResponse = yield call(
      ProductMonitor.endpoints.websites.relatedWebsites.call.bind(
        ProductMonitor.endpoints.websites.relatedWebsites,
      ),
      {
        urlParams: { id: action.website_id },
        params: (action.filters as TableParams).queryFilterValue,
      },
    );
    yield put(
      updateRelatedWebsites(
        new FetchableData<Array<RelatedWebsite>>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: (responseWebsites.accounts || []).map((website) =>
            RelatedWebsite.createFromRawModel(website),
          ),
          error: null,
        }),
      ),
    );
    yield put(updateRelatedWebsitesTotal(responseWebsites.total ?? 0));
  } catch (err) {
    yield put(
      updateRelatedWebsites(
        new FetchableData<Array<RelatedWebsite>>({
          abortController: null,
          state: FetchableDataState.ERROR,
          data: null,
          error: CustomErrorFactory.create(err),
        }),
      ),
    );
    // eslint-disable-next-line no-console
    console.error(err);
  }
}

function* updateContactInformationAccount({
  contactInformation,
  websiteId,
  onSuccess,
}: {
  contactInformation: Array<Contact>;
  websiteId: string;
  onSuccess: () => void;
}) {
  try {
    const response = yield call(
      ProductMonitor.endpoints.accounts.setContacts.call.bind(
        ProductMonitor.endpoints.accounts.setContacts,
      ),
      {
        urlParams: {
          accountId: websiteId,
        },
        data: {
          contacts: contactInformation.map((contact) =>
            contact.toSavingModel(),
          ),
        },
      },
    );
    notification.info({
      message: response.message as string,
      description: '',
      placement: 'bottomRight',
      icon: (
        <NaveeIcon.CheckGreen
          fill="var(--custom-green)"
          style={{ color: 'var(--custom-green)', fontSize: 16 }}
        />
      ),
      duration: 20,
    });
    onSuccess();
  } catch (e: any) {
    notification.error({
      message: e.message,
      placement: 'bottomRight',
      duration: 20,
    });
  } finally {
    yield put(refreshWebsiteAction({ website_id: websiteId }));
  }
}

export default function* websiteViewContainerSaga() {
  yield takeLatest(LOAD_NEXT_WEBSITES_TO_MODERATE, loadNextWebsiteToModerate);
  yield takeLatest(LOAD_PREV_WEBSITES_TO_MODERATE, loadNextWebsiteToModerate);
  yield takeLatest(LOAD_IMAGES_WEBSITE, loadImagesSaga);
  yield takeLatest(REFRESH_WEBSITE, refreshWebsite);
  yield takeLatest(LOAD_WEBSITE_RESOURCES, loadWebsiteResources);
  yield takeLatest(SET_GEO_WEBSITE, setGeoforWebsite);
  yield takeLatest(DEL_GEO_WEBSITE, delGeoforWebsite);
  yield takeLatest(PERFORM_WEBSITE_MODERATION, performWebsiteModeration);
  yield takeLatest(
    MODERATE_WEBSITE_AND_UPDATE_IN_PLACE,
    moderateWebsiteAndUpdateInPlace,
  );
  yield takeLatest(LOAD_WEBSITES_DATA, loadWebsitesDataSaga);
  yield takeLatest(LOAD_COMMENTS_WEBSITES, loadCommentsSaga);
  yield takeLatest(LOAD_RELATED_WEBSITES, loadRelatedWebsites);
  yield takeLatest(RECRAWL_WEBSITE, recrawlWebsite);
  yield takeLatest(ADD_COMMENT_WEBSITE, addCommentWebsite);
  yield takeLatest(DELETE_COMMENT_WEBSITE, deleteCommentWebsite);
  yield takeLatest(
    UPDATE_CONTACT_INFORMATION_WEBSITE,
    updateContactInformationAccount,
  );
}
