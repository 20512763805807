export const SELECT_FILTER = 'app/FiltersBar/SELECT_FILTER';
export const SET_DEFAULT_DATE = 'app/FiltersBar/SET_DEFAULT_DATE';
export const UPDATE_HIDE_MODERATION_STATISTICS =
  'app/FiltersBar/UPDATE_HIDE_MODERATION_STATISTICS';
export const RESET_ALL_FILTERS = 'app/FiltersBar/RESET_ALL_FILTERS';
export const RESET_ALL_FILTERS_EXCEPT_ONE =
  'app/FiltersBar/RESET_ALL_FILTERS_EXCEPT_ONE';
export const SET_IMAGE_LOADING = 'app/FiltersBar/SET_IMAGE_LOADING';
export const ADD_UPLOADED_IMAGE_FILTERS_BAR =
  'app/FiltersBar/ADD_UPLOADED_IMAGE_FILTERS_BAR';
export const LOAD_SAVED_FILTERS = 'app/ExploreCustomFiltersMenu/LOAD_FILTERS';
export const LOAD_FILTERS_SUCCESS =
  'app/ExploreCustomFiltersMenu/LOAD_FILTERS_SUCCESS';
export const SAVE_FILTER = 'app/ExploreCustomFiltersMenu/SAVE_FILTER';
export const DELETE_FILTER = 'app/ExploreCustomFiltersMenu/DELETE_FILTER';
export const SET_SHOW_ALL_POSTS_RESERVED_MODAL =
  'app/FeedPage/SET_SHOW_ALL_POSTS_RESERVED_MODAL';
export const RESET_STATE = 'app/ExplorePage/RESET_STATE';

// DASHBOARD FILTERS CONSTANTS
export const SET_DASHBOARD_FILTER =
  'app/DashboardFilter/SELECT_DASHBOARD_FILTER';
export const REMOVE_DASHBOARD_FILTER =
  'app/DashboardFilter/REMOVE_DASHBOARD_FILTER';
export const READ_DASHBOARD_FILTERS = 'app/Clusters/READ_DASHBOARD_FILTERS';

// CLUSTERS FILTERS CONSTANTS
export const SET_CLUSTERS_FILTER = 'app/ClustersFilter/SET_CUSTERS_FILTER';
export const REMOVE_CLUSTERS_FILTER =
  'app/ClustersFilter/REMOVE_CLUSTERS_FILTER';
export const RESET_CLUSTERS_FILTER = 'app/ClustersFilter/RESET_CLUSTERS_FILTER';
export const READ_CLUSTERS_FILTERS = 'app/Clusters/READ_CLUSTERS_FILTERS';

// FEED FILTERS CONSTANTS
export const SET_FEED_FILTERS = 'app/FeedFilters/SET_FEED_FILTERS';
export const REMOVE_FEED_FILTER = 'app/FeedFilters/REMOVE_FEED_FILTER';
export const RESET_FEED_FILTER = 'app/FeedFilters/RESET_FEED_FILTER';
export const REPLACE_FEED_FILTER = 'app/FeedFilters/REPLACE_FEED_FILTER';
export const READ_FEED_FILTERS = 'app/Clusters/READ_FEED_FILTERS';

// UPLOAD HISTORY CONSTANTS
export const SET_UPLOAD_HISTORY_FILTERS =
  'app/UploadHistoryFilters/SET_UPLOAD_HISTORY_FILTERS';
export const REMOVE_UPLOAD_HISTORY_FILTERS =
  'app/UploadHistoryFilters/REMOVE_UPLOAD_HISTORY_FILTERS';
export const RESET_UPLOAD_HISTORY_FILTERS =
  'app/UploadHistoryFilters/RESET_UPLOAD_HISTORY_FILTERS';
export const READ_UPLOAD_HISTORY_FILTERS =
  'app/Clusters/READ_UPLOAD_HISTORY_FILTERS';

export const SET_ORGANISATION_STATUSES =
  'app/FiltersBar/SET_ORGANISATION_STATUSES';
// SEARCH CONSTANTS
export const SEARCH_TERM_LIST_LIMIT = 6;

// LOADING DATA CONTANTS
export const UPDATE_FILTER_BAR_CATEGORIES =
  'app/FiltersBar/UPDATE_FILTER_BAR_CATEGORIES';
export const UPDATE_FILTERS_BAR_POST_LABELS =
  'app/FiltersBar/UPDATE_FILTERS_BAR_POST_LABELS';
export const UPDATE_FILTERS_BAR_IMAGE_LABELS =
  'app/FiltersBar/UPDATE_FILTERS_BAR_IMAGE_LABELS';
export const ADD_LOADED_WEBSITES = 'app/FiltersBar/ADD_LOADED_WEBSITES';
export const UPDATE_FILTERS_BAR_FEATURE_LABELS =
  'app/FiltersBar/UPDATE_FILTERS_BAR_FEATURE_LABELS';
export const UPDATE_INSIGHTS = 'app/FiltersBar/UPDATE_INSIGHTS';
export const LOAD_USERS = 'app/FiltersBar/LOAD_USERS';
export const UPDATE_USERS = 'app/FiltersBar/UPDATE_USERS';
export const UPDATE_ROLES = 'app/FiltersBar/UPDATE_ROLES';
export const UPDATE_LANGUAGES = 'app/FiltersBar/UPDATE_LANGUAGES';
export const UPDATE_SCRAPPING_SOURCES =
  'app/FiltersBar/UPDATE_SCRAPPING_SOURCES';
export const UPDATE_CURRENCIES = 'app/FiltersBar/UPDATE_CURRENCIES';

export const UPDATE_FILTERS_BAR_WEBSITE_CATEGORIES =
  'app/FiltersBar/UPDATE_FILTERS_BAR_WEBSITE_CATEGORIES';

export const LOAD_FILTERS_BAR_TAGS = 'app/FiltersBar/LOAD_FILTERS_BAR_TAGS';
export const UPDATE_FILTERS_BAR_TAGS = 'app/FiltersBar/UPDATE_FILTERS_BAR_TAGS';
export const UPDATE_FILTERS_BAR_AVAILABLE_CONTACT_TYPES =
  'app/FiltersBar/UPDATE_FILTERS_BAR_AVAILABLE_CONTACT_TYPES';
export const UPDATE_FILTERS_BAR_CRAWLING_DOMAINS =
  'app/FiltersBar/UPDATE_FILTERS_BAR_CRAWLING_DOMAINS';
export const UPDATE_FILTERS_BAR_GEOGRAPHY =
  'app/FiltersBar/UPDATE_FILTERS_BAR_GEOGRAPHY';

export const LOAD_GLOBAL_DATA = 'app/FiltersBar/LOAD_GLOBAL_DATA';
export const UPDATE_FILTERS_BAR_WEBSITES =
  'app/FiltersBar/UPDATE_FILTERS_BAR_WEBSITES';

export const UPDATE_FILTERS_BAR_ACCOUNT_LABELS =
  'app/FiltersBar/UPDATE_FILTERS_BAR_ACCOUNT_LABELS';

/* search by image */
export const LOAD_IMAGE_FOR_SEARCH = 'app/FiltersBar/LOAD_IMAGE_FOR_SEARCH';
export const LOAD_IMAGE_BY_FILE_FOR_SEARCH =
  'app/FiltersBar/LOAD_IMAGE_BY_FILE_FOR_SEARCH';
export const LOAD_IMAGE_BY_ID_FOR_SEARCH =
  'app/FiltersBar/LOAD_IMAGE_BY_ID_FOR_SEARCH';
export const UPDATE_IMAGE_FOR_SEARCH = 'app/FiltersBar/UPDATE_IMAGE_FOR_SEARCH';

export const LOAD_SEARCH_ITEMS_DATA = 'app/FiltersBar/LOAD_SEARCH_ITEMS_DATA';
export const UPDATE_ADVANCED_STATISTICS =
  'app/FiltersBar/UPDATE_ADVANCED_STATISTICS';
export const LOAD_ADVANCED_STATISTICS =
  'app/FiltersBar/LOAD_ADVANCED_STATISTICS';
