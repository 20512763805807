import dayjs from 'dayjs';
import { ModerationMethodEnum } from '../moderationMethod/ModerationMethod';

export type SavedFilterType = 'image' | 'post' | 'account' | 'website';

export interface SavedFilterRaw {
  account_moderation_status: Array<string>;
  account_status_type: Array<string>;
  account_tags_to_include: Array<number> | undefined;
  account_tags_to_exclude: Array<number> | undefined;
  account_poster_id: Array<number | string> | undefined;
  account_website_id: Array<number | string> | undefined;
  all_tracked_websites: 'false' | 'true';
  all_untracked_websites: 'false' | 'true';
  category_id: Array<number>;
  cluster_id: Array<string>;
  cluster_tags_to_include: Array<number> | undefined;
  cluster_tags_to_exclude: Array<number> | undefined;
  global_category_ids: Array<string>;
  commenter_id: Array<number>;
  comments_to_exclude: Array<string>;
  comments_to_include: Array<string>;
  date_filter_type: string | undefined;
  upload_status: Array<string>;
  duplicated_group_tags_to_include: Array<number> | undefined;
  duplicated_group_tags_to_exclude: Array<number> | undefined;
  filter_type: SavedFilterType;
  followers_count: [number | null, number | null];
  geography_to_exclude: Array<string>;
  geography_to_include: Array<string>;
  hise: Array<string>;
  id: number;
  interval: string;
  is_default: boolean;
  image_moderation_reasons: Array<string>;
  image_moderation_status: Array<string>;
  keywords_to_exclude: Array<string>;
  keywords_to_include: Array<string>;
  maximum_stock: number | undefined;
  minimum_stock: number | undefined;
  label_type: Array<string>;
  label_type_to_exclude: Array<string>;
  label_type_to_include: Array<string>;
  label_types_logic: 'AND';
  min_external_navee_score: number;
  min_navee_score: number;
  moderation_method: ModerationMethodEnum;
  moderator_id: Array<number>;
  name: string;
  organisation_id: number;
  post_moderation_reasons: Array<string>;
  post_moderation_status: Array<string>;
  post_sources: Array<string>;
  post_tags_to_include: Array<number> | undefined;
  post_tags_to_exclude: Array<number> | undefined;
  price_range: [number | null, number | null];
  start_date: string | null;
  end_date: string | null;
  qa_checker_id: Array<number>;
  reverse: boolean;
  sort_by: string;
  status_type: Array<string>;
  taken_down: Array<'true' | 'false' | 'null'>;
  validator_id: Array<number>;
  website_category_id: Array<number>;
  website_category_id_to_exclude: Array<number>;
  website_id: Array<string>;
  website_id_to_exclude: Array<string>;
  related_to_account_id: Array<string | number>;
  account_tag_name_contains_to_exclude: Array<string> | undefined;
  account_tag_name_contains_to_include: Array<string> | undefined;
  post_tag_name_contains_to_exclude: Array<string> | undefined;
  post_tag_name_contains_to_include: Array<string> | undefined;
  image_tag_name_contains_to_exclude: Array<string> | undefined;
  image_tag_name_contains_to_include: Array<string> | undefined;
  cluster_tag_name_contains_to_exclude: Array<string> | undefined;
  cluster_tag_name_contains_to_include: Array<string> | undefined;
  upload_tags_to_include: Array<number> | undefined;
}

export class SavedFilterModel {
  accountModerationStatus: Array<string>;

  accountStatusType: Array<string>;

  accountTagsToInclude: Array<number>;

  accountTagsToExclude: Array<number>;

  accountPosterId: Array<number | string>;

  accountWebsiteId: Array<number | string>;

  allTrackedWebsites: boolean;

  allUntrackedWebsites: boolean;

  categoryId: Array<number>;

  clusterId: Array<string>;

  clusterTagsToInclude: Array<number>;

  clusterTagsToExclude: Array<number>;

  globalCategoryIds: Array<string>;

  commenterId: Array<number>;

  commentsToExclude: Array<string>;

  commentsToInclude: Array<string>;

  dateFilterType: string;

  duplicatedGroupTagsToInclude: Array<number>;

  duplicatedGroupTagsToExclude: Array<number>;

  filterType: SavedFilterType;

  followersCount: [number | null, number | null];

  geographyToExclude: Array<string>;

  geographyToInclude: Array<string>;

  hise: Array<string>;

  id: number;

  isDefault: boolean;

  interval: string;

  imageModerationReasons: Array<string>;

  imageModerationStatus: Array<string>;

  keywordsToExclude: Array<string>;

  keywordsToInclude: Array<string>;

  labelType: Array<string>;

  labelTypeToExclude: Array<string>;

  labelTypeToInclude: Array<string>;

  labelTypesLogic: 'AND';

  minExternalNaveeScore: number;

  minNaveeScore: number;

  moderationMethod: ModerationMethodEnum;

  moderatorId: Array<number>;

  maxStock: number | undefined;

  minStock: number | undefined;

  name: string;

  organisationId: number;

  postModerationReasons: Array<string>;

  postModerationStatus: Array<string>;

  startDate: dayjs.Dayjs | null;

  endDate: dayjs.Dayjs | null;

  postSources: Array<string>;

  postTagsToInclude: Array<number>;

  postTagsToExclude: Array<number>;

  priceRange: [number | null, number | null];

  qaCheckerId: Array<number>;

  reverse: boolean;

  sortBy: string;

  statusType: Array<string>;

  takenDown: Array<'true' | 'false' | 'null'>;

  validatorId: Array<number>;

  websiteCategoryId: Array<number>;

  websiteCategoryIdToExclude: Array<number>;

  websiteId: Array<string>;

  websiteIdToExclude: Array<string>;

  relatedToAccountId: Array<string | number>;

  accountTagNameContainsToExclude: Array<string>;

  accountTagNameContainsToInclude: Array<string>;

  postTagNameContainsToExclude: Array<string>;

  postTagNameContainsToInclude: Array<string>;

  imageTagNameContainsToExclude: Array<string>;

  imageTagNameContainsToInclude: Array<string>;

  clusterTagNameContainsToExclude: Array<string>;

  clusterTagNameContainsToInclude: Array<string>;

  uploadTagsToInclude: Array<number>;

  uploadStatus: Array<string>;

  constructor(
    props: Pick<
      SavedFilterModel,
      | 'accountModerationStatus'
      | 'accountStatusType'
      | 'accountTagsToExclude'
      | 'accountTagsToInclude'
      | 'allTrackedWebsites'
      | 'allUntrackedWebsites'
      | 'accountPosterId'
      | 'accountWebsiteId'
      | 'categoryId'
      | 'clusterTagsToExclude'
      | 'clusterTagsToInclude'
      | 'clusterId'
      | 'commenterId'
      | 'commentsToExclude'
      | 'commentsToInclude'
      | 'dateFilterType'
      | 'duplicatedGroupTagsToExclude'
      | 'duplicatedGroupTagsToInclude'
      | 'filterType'
      | 'followersCount'
      | 'geographyToExclude'
      | 'geographyToInclude'
      | 'globalCategoryIds'
      | 'hise'
      | 'id'
      | 'imageModerationReasons'
      | 'imageModerationStatus'
      | 'isDefault'
      | 'interval'
      | 'keywordsToExclude'
      | 'keywordsToInclude'
      | 'labelType'
      | 'labelTypeToExclude'
      | 'labelTypeToInclude'
      | 'endDate'
      | 'maxStock'
      | 'minStock'
      | 'startDate'
      | 'labelTypesLogic'
      | 'minExternalNaveeScore'
      | 'minNaveeScore'
      | 'moderationMethod'
      | 'moderatorId'
      | 'name'
      | 'organisationId'
      | 'postModerationReasons'
      | 'postModerationStatus'
      | 'postSources'
      | 'postTagsToExclude'
      | 'postTagsToInclude'
      | 'priceRange'
      | 'qaCheckerId'
      | 'relatedToAccountId'
      | 'reverse'
      | 'sortBy'
      | 'statusType'
      | 'takenDown'
      | 'validatorId'
      | 'websiteCategoryId'
      | 'websiteCategoryIdToExclude'
      | 'accountTagNameContainsToExclude'
      | 'accountTagNameContainsToInclude'
      | 'clusterTagNameContainsToExclude'
      | 'clusterTagNameContainsToInclude'
      | 'imageTagNameContainsToExclude'
      | 'imageTagNameContainsToInclude'
      | 'postTagNameContainsToExclude'
      | 'postTagNameContainsToInclude'
      | 'websiteId'
      | 'uploadTagsToInclude'
      | 'uploadStatus'
      | 'websiteIdToExclude'
    >,
  ) {
    this.accountModerationStatus = props.accountModerationStatus;
    this.accountStatusType = props.accountStatusType;
    this.accountTagsToExclude = props.accountTagsToExclude;
    this.accountTagsToInclude = props.accountTagsToInclude;
    this.accountPosterId = props.accountPosterId;
    this.accountWebsiteId = props.accountWebsiteId;
    this.allTrackedWebsites = props.allTrackedWebsites;
    this.allUntrackedWebsites = props.allUntrackedWebsites;
    this.categoryId = props.categoryId;
    this.clusterId = props.clusterId;
    this.clusterTagsToExclude = props.clusterTagsToExclude;
    this.clusterTagsToInclude = props.clusterTagsToInclude;
    this.globalCategoryIds = props.globalCategoryIds;
    this.commenterId = props.commenterId;
    this.commentsToExclude = props.commentsToExclude;
    this.commentsToInclude = props.commentsToInclude;
    this.dateFilterType = props.dateFilterType;
    this.duplicatedGroupTagsToExclude = props.duplicatedGroupTagsToExclude;
    this.duplicatedGroupTagsToInclude = props.duplicatedGroupTagsToInclude;
    this.filterType = props.filterType;
    this.followersCount = props.followersCount;
    this.geographyToExclude = props.geographyToExclude;
    this.geographyToInclude = props.geographyToInclude;
    this.hise = props.hise;
    this.id = props.id;
    this.isDefault = props.isDefault;
    this.interval = props.interval;
    this.imageModerationReasons = props.imageModerationReasons;
    this.imageModerationStatus = props.imageModerationStatus;
    this.startDate = props.startDate;
    this.endDate = props.endDate;
    this.keywordsToExclude = props.keywordsToExclude;
    this.keywordsToInclude = props.keywordsToInclude;
    this.labelType = props.labelType;
    this.labelTypeToExclude = props.labelTypeToExclude;
    this.labelTypeToInclude = props.labelTypeToInclude;
    this.labelTypesLogic = props.labelTypesLogic;
    this.minExternalNaveeScore = props.minExternalNaveeScore;
    this.minNaveeScore = props.minNaveeScore;
    this.moderationMethod = props.moderationMethod;
    this.moderatorId = props.moderatorId;
    this.maxStock = props.maxStock;
    this.minStock = props.minStock;
    this.name = props.name;
    this.organisationId = props.organisationId;
    this.postModerationReasons = props.postModerationReasons;
    this.postModerationStatus = props.postModerationStatus;
    this.postSources = props.postSources;
    this.postTagsToExclude = props.postTagsToExclude;
    this.postTagsToInclude = props.postTagsToInclude;
    this.priceRange = props.priceRange;
    this.qaCheckerId = props.qaCheckerId;
    this.relatedToAccountId = props.relatedToAccountId;
    this.reverse = props.reverse;
    this.sortBy = props.sortBy;
    this.statusType = props.statusType;
    this.takenDown = props.takenDown;
    this.validatorId = props.validatorId;
    this.websiteCategoryId = props.websiteCategoryId;
    this.websiteCategoryIdToExclude = props.websiteCategoryIdToExclude;
    this.websiteId = props.websiteId;
    this.websiteIdToExclude = props.websiteIdToExclude;
    this.accountTagNameContainsToExclude =
      props.accountTagNameContainsToExclude;
    this.accountTagNameContainsToInclude =
      props.accountTagNameContainsToInclude;
    this.clusterTagNameContainsToExclude =
      props.clusterTagNameContainsToExclude;
    this.clusterTagNameContainsToInclude =
      props.clusterTagNameContainsToInclude;
    this.imageTagNameContainsToExclude = props.imageTagNameContainsToExclude;
    this.imageTagNameContainsToInclude = props.imageTagNameContainsToInclude;
    this.postTagNameContainsToExclude = props.postTagNameContainsToExclude;
    this.postTagNameContainsToInclude = props.postTagNameContainsToInclude;
    this.uploadTagsToInclude = props.uploadTagsToInclude;
    this.uploadStatus = props.uploadStatus;
  }

  static fromRawModel(raw: SavedFilterRaw): SavedFilterModel {
    return new SavedFilterModel({
      accountModerationStatus: raw.account_moderation_status,
      accountStatusType: raw.account_status_type,
      accountTagsToExclude: raw.account_tags_to_exclude ?? [],
      accountTagsToInclude: raw.account_tags_to_include ?? [],
      accountPosterId: raw.account_poster_id ?? [],
      accountWebsiteId: raw.account_website_id ?? [],
      allTrackedWebsites: raw.all_tracked_websites === 'true',
      allUntrackedWebsites: raw.all_untracked_websites === 'true',
      categoryId: raw.category_id,
      clusterId: raw.cluster_id,
      clusterTagsToExclude: raw.cluster_tags_to_exclude ?? [],
      clusterTagsToInclude: raw.cluster_tags_to_include ?? [],
      globalCategoryIds: raw.global_category_ids,
      commenterId: raw.commenter_id,
      commentsToExclude: raw.comments_to_exclude,
      commentsToInclude: raw.comments_to_include,
      dateFilterType: raw.date_filter_type ?? 'crawling_date',
      duplicatedGroupTagsToExclude: raw.duplicated_group_tags_to_exclude ?? [],
      duplicatedGroupTagsToInclude: raw.duplicated_group_tags_to_include ?? [],
      filterType: raw.filter_type,
      followersCount: raw.followers_count,
      geographyToExclude: raw.geography_to_exclude,
      geographyToInclude: raw.geography_to_include,
      hise: raw.hise,
      id: raw.id,
      isDefault: raw.is_default,
      imageModerationReasons: raw.image_moderation_reasons,
      imageModerationStatus: raw.image_moderation_status,
      keywordsToExclude: raw.keywords_to_exclude,
      keywordsToInclude: raw.keywords_to_include,
      labelType: raw.label_type,
      interval: raw.interval,
      startDate: raw.start_date ? dayjs(raw.start_date) : null,
      maxStock: raw.maximum_stock,
      minStock: raw.minimum_stock,
      endDate: raw.end_date ? dayjs(raw.end_date) : null,
      labelTypeToExclude: raw.label_type_to_exclude,
      labelTypeToInclude: raw.label_type_to_include,
      labelTypesLogic: raw.label_types_logic,
      minExternalNaveeScore: raw.min_external_navee_score,
      minNaveeScore: raw.min_navee_score,
      moderationMethod: raw.moderation_method,
      moderatorId: raw.moderator_id,
      name: raw.name,
      organisationId: raw.organisation_id,
      postModerationReasons: raw.post_moderation_reasons,
      postModerationStatus: raw.post_moderation_status,
      postSources: raw.post_sources,
      postTagsToExclude: raw.post_tags_to_exclude ?? [],
      postTagsToInclude: raw.post_tags_to_include ?? [],
      priceRange: raw.price_range,
      qaCheckerId: raw.qa_checker_id,
      relatedToAccountId: raw.related_to_account_id,
      reverse: raw.reverse,
      sortBy: raw.sort_by,
      statusType: raw.status_type,
      takenDown: raw.taken_down,
      validatorId: raw.validator_id,
      websiteCategoryId: raw.website_category_id,
      websiteCategoryIdToExclude: raw.website_category_id_to_exclude,
      websiteId: raw.website_id,
      websiteIdToExclude: raw.website_id_to_exclude,
      accountTagNameContainsToExclude:
        raw.account_tag_name_contains_to_exclude ?? [],
      accountTagNameContainsToInclude:
        raw.account_tag_name_contains_to_include ?? [],
      clusterTagNameContainsToExclude:
        raw.cluster_tag_name_contains_to_exclude ?? [],
      clusterTagNameContainsToInclude:
        raw.cluster_tag_name_contains_to_include ?? [],
      imageTagNameContainsToExclude:
        raw.image_tag_name_contains_to_exclude ?? [],
      imageTagNameContainsToInclude:
        raw.image_tag_name_contains_to_include ?? [],
      postTagNameContainsToExclude: raw.post_tag_name_contains_to_exclude ?? [],
      postTagNameContainsToInclude: raw.post_tag_name_contains_to_include ?? [],
      uploadTagsToInclude: raw.upload_tags_to_include ?? [],
      uploadStatus: raw.upload_status,
    });
  }

  static fromPartialRawModel(raw: Partial<SavedFilterRaw>): SavedFilterModel {
    return new SavedFilterModel({
      accountModerationStatus: raw.account_moderation_status ?? [],
      accountStatusType: raw.account_status_type ?? [],
      accountTagsToExclude: raw.account_tags_to_exclude ?? [],
      accountTagsToInclude: raw.account_tags_to_include ?? [],
      accountPosterId: raw.account_poster_id ?? [],
      accountWebsiteId: raw.account_website_id ?? [],
      allTrackedWebsites: raw.all_tracked_websites === 'true',
      allUntrackedWebsites: raw.all_untracked_websites === 'true',
      categoryId: raw.category_id ?? [],
      clusterId: raw.cluster_id ?? [],
      clusterTagsToExclude: raw.cluster_tags_to_exclude ?? [],
      clusterTagsToInclude: raw.cluster_tags_to_include ?? [],
      globalCategoryIds: raw.global_category_ids ?? [],
      commenterId: raw.commenter_id ?? [],
      commentsToExclude: raw.comments_to_exclude ?? [],
      commentsToInclude: raw.comments_to_include ?? [],
      dateFilterType: raw.date_filter_type ?? 'crawling_date',
      duplicatedGroupTagsToExclude: raw.duplicated_group_tags_to_exclude ?? [],
      duplicatedGroupTagsToInclude: raw.duplicated_group_tags_to_include ?? [],
      filterType: raw.filter_type ?? 'post',
      followersCount: raw.followers_count ?? [null, null],
      geographyToExclude: raw.geography_to_exclude ?? [],
      geographyToInclude: raw.geography_to_include ?? [],
      hise: raw.hise ?? [],
      id: raw.id ?? NaN,
      isDefault: raw.is_default ?? false,
      minStock: raw.minimum_stock ?? 0,
      maxStock: raw.maximum_stock ?? 0,
      imageModerationReasons: raw.image_moderation_reasons ?? [],
      imageModerationStatus: raw.image_moderation_status ?? [],
      keywordsToExclude: raw.keywords_to_exclude ?? [],
      keywordsToInclude: raw.keywords_to_include ?? [],
      startDate: raw.start_date ? dayjs(raw.start_date) : null,
      endDate: raw.end_date ? dayjs(raw.end_date) : null,
      labelType: raw.label_type ?? [],
      interval: raw.interval ?? '',
      labelTypeToExclude: raw.label_type_to_exclude ?? [],
      labelTypeToInclude: raw.label_type_to_include ?? [],
      labelTypesLogic: raw.label_types_logic ?? 'AND',
      minExternalNaveeScore: raw.min_external_navee_score ?? 0,
      minNaveeScore: raw.min_navee_score ?? 0,
      moderationMethod: raw.moderation_method ?? ModerationMethodEnum.all,
      moderatorId: raw.moderator_id ?? [],
      name: raw.name ?? '',
      organisationId: raw.organisation_id ?? 0,
      postModerationReasons: raw.post_moderation_reasons ?? [],
      postModerationStatus: raw.post_moderation_status ?? [],
      postSources: raw.post_sources ?? [],
      postTagsToExclude: raw.post_tags_to_exclude ?? [],
      postTagsToInclude: raw.post_tags_to_include ?? [],
      priceRange: raw.price_range ?? [null, null],
      qaCheckerId: raw.qa_checker_id ?? [],
      relatedToAccountId: raw.related_to_account_id ?? [],
      reverse: raw.reverse ?? false,
      sortBy: raw.sort_by ?? '',
      statusType: raw.status_type ?? [],
      takenDown: raw.taken_down ?? [],
      validatorId: raw.validator_id ?? [],
      websiteCategoryId: raw.website_category_id ?? [],
      websiteCategoryIdToExclude: raw.website_category_id_to_exclude ?? [],
      websiteId: raw.website_id ?? [],
      websiteIdToExclude: raw.website_id_to_exclude ?? [],
      accountTagNameContainsToExclude:
        raw.account_tag_name_contains_to_exclude ?? [],
      accountTagNameContainsToInclude:
        raw.account_tag_name_contains_to_include ?? [],
      clusterTagNameContainsToExclude:
        raw.cluster_tag_name_contains_to_exclude ?? [],
      clusterTagNameContainsToInclude:
        raw.cluster_tag_name_contains_to_include ?? [],
      imageTagNameContainsToExclude:
        raw.image_tag_name_contains_to_exclude ?? [],
      imageTagNameContainsToInclude:
        raw.image_tag_name_contains_to_include ?? [],
      postTagNameContainsToExclude: raw.post_tag_name_contains_to_exclude ?? [],
      postTagNameContainsToInclude: raw.post_tag_name_contains_to_include ?? [],
      uploadTagsToInclude: raw.upload_tags_to_include ?? [],
      uploadStatus: raw.upload_status ?? [],
    });
  }

  toRawModel(): SavedFilterRaw {
    return {
      account_moderation_status: this.accountModerationStatus,
      account_status_type: this.accountStatusType,
      account_tags_to_exclude: this.accountTagsToExclude,
      account_tags_to_include: this.accountTagsToInclude,
      maximum_stock: this.maxStock,
      minimum_stock: this.minStock,
      account_poster_id: this.accountPosterId,
      account_website_id: this.accountWebsiteId,
      all_tracked_websites: this.allTrackedWebsites ? 'true' : 'false',
      all_untracked_websites: this.allUntrackedWebsites ? 'true' : 'false',
      category_id: this.categoryId,
      cluster_id: this.clusterId,
      cluster_tags_to_exclude: this.clusterTagsToExclude,
      cluster_tags_to_include: this.clusterTagsToInclude,
      global_category_ids: this.globalCategoryIds,
      commenter_id: this.commenterId,
      comments_to_exclude: this.commentsToExclude,
      comments_to_include: this.commentsToInclude,
      date_filter_type: this.dateFilterType,
      duplicated_group_tags_to_exclude: this.duplicatedGroupTagsToExclude,
      duplicated_group_tags_to_include: this.duplicatedGroupTagsToInclude,
      filter_type: this.filterType,
      followers_count: this.followersCount,
      geography_to_exclude: this.geographyToExclude,
      geography_to_include: this.geographyToInclude,
      end_date: this.endDate ? this.endDate.format('YYYY-MM-DD') : null,
      start_date: this.startDate ? this.startDate.format('YYYY-MM-DD') : null,
      hise: this.hise,
      id: this.id,
      interval: this.interval,
      is_default: this.isDefault,
      image_moderation_reasons: this.imageModerationReasons,
      image_moderation_status: this.imageModerationStatus,
      keywords_to_exclude: this.keywordsToExclude,
      keywords_to_include: this.keywordsToInclude,
      label_type: this.labelType,
      label_type_to_exclude: this.labelTypeToExclude,
      label_type_to_include: this.labelTypeToInclude,
      label_types_logic: this.labelTypesLogic,
      min_external_navee_score: this.minExternalNaveeScore,
      min_navee_score: this.minNaveeScore,
      moderation_method: this.moderationMethod,
      moderator_id: this.moderatorId,
      name: this.name,
      organisation_id: this.organisationId,
      post_moderation_reasons: this.postModerationReasons,
      post_moderation_status: this.postModerationStatus,
      post_sources: this.postSources,
      post_tags_to_exclude: this.postTagsToExclude,
      post_tags_to_include: this.postTagsToInclude,
      price_range: this.priceRange,
      qa_checker_id: this.qaCheckerId,
      related_to_account_id: this.relatedToAccountId,
      reverse: this.reverse,
      sort_by: this.sortBy,
      status_type: this.statusType,
      taken_down: this.takenDown,
      validator_id: this.validatorId,
      website_category_id: this.websiteCategoryId,
      website_category_id_to_exclude: this.websiteCategoryIdToExclude,
      website_id: this.websiteId,
      website_id_to_exclude: this.websiteIdToExclude,
      account_tag_name_contains_to_exclude:
        this.accountTagNameContainsToExclude,
      account_tag_name_contains_to_include:
        this.accountTagNameContainsToInclude,
      post_tag_name_contains_to_exclude: this.postTagNameContainsToExclude,
      post_tag_name_contains_to_include: this.postTagNameContainsToInclude,
      image_tag_name_contains_to_exclude: this.imageTagNameContainsToExclude,
      image_tag_name_contains_to_include: this.imageTagNameContainsToInclude,
      cluster_tag_name_contains_to_exclude:
        this.clusterTagNameContainsToExclude,
      cluster_tag_name_contains_to_include:
        this.clusterTagNameContainsToInclude,
      upload_tags_to_include: this.uploadTagsToInclude,
      upload_status: this.uploadStatus,
    };
  }

  prepareForCompareWithLocation() {
    return [
      ...Object.entries(this.toRawModel()).reduce((acc, item) => {
        const [key, value] = item;
        if (
          key === 'price_range' &&
          Array.isArray(value) &&
          value.length === 2 &&
          value.some(Boolean)
        ) {
          acc.set('maximum_price', [value[1]?.toString()]);
          acc.set('minimum_price', [value[0]?.toString()]);
          return acc;
        }
        if (
          key === 'followers_count' &&
          Array.isArray(value) &&
          value.length === 2 &&
          value.some(Boolean)
        ) {
          acc.set('maximum_followers_count', [value[1]?.toString()]);
          acc.set('minimum_followers_count', [value[0]?.toString()]);
          return acc;
        }
        if (
          [
            'all_tracked_websites',
            'all_untracked_websites',
            'filter_type',
            'price_range',
            'interval',
            'followers_count',
            'category_id',
            'id',
            'is_default',
            'name',
            'organisation_id',
            'min_navee_score',
            'min_external_navee_score',
            'start_date',
          ].includes(key)
        ) {
          return acc;
        }
        if (
          value === undefined ||
          (Array.isArray(value) && !value.length) ||
          (Array.isArray(value) && value.every((a) => a === null)) ||
          value === null ||
          value === ''
        ) {
          return acc;
        }
        const modifiedValue =
          typeof value === 'boolean' || typeof value === 'number'
            ? value.toString()
            : value;
        const currentValue = acc.get(key);
        if (currentValue) {
          if (Array.isArray(currentValue)) {
            if (Array.isArray(modifiedValue)) {
              return acc.set(key, [
                ...currentValue,
                ...modifiedValue.map((v) => v.toString()),
              ]);
            }
            return acc.set(key, [...currentValue, modifiedValue]);
          }
          if (Array.isArray(modifiedValue)) {
            return acc.set(key, [
              currentValue,
              ...modifiedValue.map((v) => v.toString()),
            ]);
          }
          return acc.set(key, [currentValue, modifiedValue]);
        }
        if (Array.isArray(modifiedValue)) {
          return acc.set(
            key,
            modifiedValue.map((v) => v?.toString()),
          );
        }
        return acc.set(key, [modifiedValue]);
      }, new Map()),
    ].sort(([a], [b]) => a.localeCompare(b)).map(([key, value]) => {
      return [key, [...new Set(value).values()]];
    });
  }

  static createEmpty(): SavedFilterModel {
    return new SavedFilterModel({
      accountModerationStatus: [],
      accountStatusType: [],
      accountTagsToExclude: [],
      accountTagsToInclude: [],
      accountPosterId: [],
      accountWebsiteId: [],
      allTrackedWebsites: false,
      allUntrackedWebsites: false,
      categoryId: [],
      clusterId: [],
      clusterTagsToExclude: [],
      clusterTagsToInclude: [],
      globalCategoryIds: [],
      commenterId: [],
      commentsToExclude: [],
      commentsToInclude: [],
      dateFilterType: 'crawling_date',
      duplicatedGroupTagsToExclude: [],
      duplicatedGroupTagsToInclude: [],
      filterType: 'post',
      followersCount: [null, null],
      geographyToExclude: [],
      geographyToInclude: [],
      hise: [],
      id: NaN,
      isDefault: false,
      interval: '',
      imageModerationReasons: [],
      imageModerationStatus: [],
      keywordsToExclude: [],
      keywordsToInclude: [],
      labelType: [],
      labelTypeToExclude: [],
      labelTypeToInclude: [],
      labelTypesLogic: 'AND',
      minExternalNaveeScore: 0,
      minNaveeScore: 0,
      moderationMethod: ModerationMethodEnum.all,
      moderatorId: [],
      maxStock: 0,
      minStock: 0,
      name: '',
      organisationId: 0,
      postModerationReasons: [],
      postModerationStatus: [],
      postSources: [],
      postTagsToExclude: [],
      postTagsToInclude: [],
      priceRange: [null, null],
      qaCheckerId: [],
      relatedToAccountId: [],
      reverse: false,
      sortBy: '',
      statusType: [],
      takenDown: [],
      validatorId: [],
      websiteCategoryId: [],
      websiteCategoryIdToExclude: [],
      websiteId: [],
      websiteIdToExclude: [],
      accountTagNameContainsToExclude: [],
      accountTagNameContainsToInclude: [],
      clusterTagNameContainsToExclude: [],
      clusterTagNameContainsToInclude: [],
      imageTagNameContainsToExclude: [],
      imageTagNameContainsToInclude: [],
      postTagNameContainsToExclude: [],
      postTagNameContainsToInclude: [],
      uploadTagsToInclude: [],
      uploadStatus: [],
      endDate: null,
      startDate: null,
    });
  }
}
