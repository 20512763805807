import React from 'react';
import reactStringReplace from 'react-string-replace';
import { Dropdown, Menu } from 'antd';
import { UserLightModel } from 'product-types/src/domain/user/UserLightModel';
import { Suggestion } from '../components/Comments/suggestion';

export const getUserFromString = (str, users: Array<UserLightModel> = []) => {
  const matched = parseInt(str.match(/\d+/)[0], 10);
  return (users || []).find((us) => us.id === matched);
};

export const replace_user_id_with_user_and_dropdown = (text, users) =>
          reactStringReplace(text, /(@user-id-\d+)/g, (match, i) => {
    const userFromString = getUserFromString(match, users);
    const {
      email = '',
      name = '',
      profilePictureLink = '',
    } = userFromString || {};
    const menu = (
      <Menu
        items={[
          {
            key: '1',
            label: (<Menu.Item><Suggestion email={email} name={name} image={profilePictureLink} /></Menu.Item>),
          },
        ]}
      />
    );
    return (
      <Dropdown dropdownRender={() => menu} overlayClassName="person-overlay">
        <span
          key={match + i}
          className="person"
          style={{ cursor: 'pointer', color: 'var(--dodger-blue)' }}
        >
          {name}
        </span>
      </Dropdown>
    );
  });
