/* eslint-disable no-console */
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { TagModel, TagTypeEnum } from 'product-types/src/domain/tag/Tag';
import {
  FetchableData,
  FetchableDataState,
} from 'product-types/src/common/FetchableData/FetchableData';
import { ModerationReason } from 'product-types/src/domain/moderationReason/ModerationReason';
import {
  CustomError,
  CustomErrorFactory,
} from 'product-types/src/common/Error/CustomError';
import { WebsiteCategory } from 'product-types/src/domain/website/WebsiteCategory';
import {
  CountryToGeo,
  ZoneToGeo,
  GeographySelectOptions,
  Geographic,
} from 'product-types/src/domain/geo/Geo';
import { UserLightModel } from 'product-types/src/domain/user/UserLightModel';
import { WebsiteSuggestion } from 'product-types/src/domain/website/Website';
import {
  Label,
  mapLabelRawToLabel,
} from 'product-types/src/domain/label/Label';
import { ImageModerationRawModel } from 'product-types/src/domain/image/ImageModerationModel';
import { SearchImagesResponse } from 'product-types/src/domain/duplicatedGroup/SearchedImage';
import { ExportTemplateModel } from 'product-types/src/domain/export/ExportTemplate';
import { ProductCategoryTree } from 'product-types/src/domain/productCategory';
import { base64ToJson } from 'product-utils/src/object';
import { Insight } from 'product-types/src/domain/insight/insight';
import { AdvancedStatistics } from 'product-types/src/domain/advancedStats/AdvancedStats';
import {
  RequestParamsAdvancedStatistics,
  ResponseAdvancedStatistics,
} from 'types/network/AdvancedStatistics/AdvancedStatistics';
import { snakeToCamelNested } from 'product-types/src/transform/snakeToCamelNested';
import { AppState } from 'store/storeAccess';
import { FeedFilterModel } from 'types/filters/MoleculesFilterImplementation/FeedFilterModel';
import { notification } from 'antd';
import { LOAD_LABELS } from 'containers/DashboardPage/constants';
import { RoleModel } from 'product-types/src/domain/role/Role';
import { ContactMainInfo } from 'product-types/src/domain/contact/Contact';
import { Currency } from 'product-types/src/domain/Currency';
import { OrganisationStatus } from 'product-types/src/domain/organisation/OrganisationStatus';
import { LanguageModel } from 'product-types/src/domain/language/language';
import { CrawlingDomain } from 'product-types/src/domain/crawlingDomain';
import { ScrappingSource } from 'product-types/src/domain/scrappingSource/ScrappingSource';
import {
  SavedFilterModel,
  SavedFilterRaw,
} from 'product-types/src/domain/savedFilters/SavedFilters';
import ProductMonitor from '../../types/network/Http/productMonitor/index';
import { TagsReasonResponse } from '../../types/network/Http/productMonitor/endpoints/me/organisation/tags';
import { WebsiteNamesResponse } from '../../types/network/Http/productMonitor/endpoints/me/websiteNames';
import { UsersResponse } from '../../types/network/Http/productMonitor/endpoints/users/getAll';
import { SearchFilter } from '../../types/filters/AtomicFiltersImplementation/Search/Search';
import {
  DuplicatedGroupSearchItemModelAdditionalInfo,
  SearchBarType,
  SearchItemModel,
} from '../../types/filters/AtomicFiltersImplementation/Search/SearchItem';
import { OrganisationLabelsResponse } from '../../types/network/Http/productMonitor/endpoints/me/organisation/labels/all';
import {
  loadFiltersAction,
  loadFilterSuccessAction,
  loadImageByIdForSearch as loadImageByIdForSearchAction,
  LoadImageForSearchProps,
  loadSearchItemsData as loadSearchItemsDataAction,
  saveFilterAction,
  setOrganisationStatuses,
  updateAccountLabelsFilterBar,
  updateAdvancedStatistics,
  updateAvailableContactTypes,
  updateAvailableLanguages,
  updateCategoriesFilterBar,
  updateCrawlingDomain,
  updateCurrencies,
  updateFeatureLabelesOptions,
  updateFilterBarTags,
  updateFiltersBarGeography,
  updateFiltersBarWebsiteCategoriesAction,
  updateFiltersBarWebsites,
  updateImageForSearch,
  updateImageLabelsFilterBar,
  updateInsights,
  updateLabelsFilterBar,
  updateRoles,
  updateScrappingSource,
  updateUsers,
} from './actions';
import {
  DELETE_FILTER,
  LOAD_ADVANCED_STATISTICS,
  LOAD_FILTERS_BAR_TAGS,
  LOAD_GLOBAL_DATA,
  LOAD_IMAGE_BY_FILE_FOR_SEARCH,
  LOAD_IMAGE_BY_ID_FOR_SEARCH,
  LOAD_IMAGE_FOR_SEARCH,
  LOAD_SAVED_FILTERS,
  LOAD_SEARCH_ITEMS_DATA,
  LOAD_USERS,
  SAVE_FILTER,
} from './constants';
import {
  GlobalDataResponse,
  TopWebsites,
} from '../../types/network/Http/productMonitor/endpoints/me/globalData';
import { updatePostExportTemplates } from '../Navbar/actions';

function* loadLabels() {
  yield put(
    updateLabelsFilterBar(
      new FetchableData<Array<Label>>({
        abortController: null,
        state: FetchableDataState.LOADING,
        data: null,
        error: null,
      }),
    ),
  );
  yield put(
    updateImageLabelsFilterBar(
      new FetchableData<Array<Label>>({
        abortController: null,
        state: FetchableDataState.LOADING,
        data: null,
        error: null,
      }),
    ),
  );
  yield put(
    updateAccountLabelsFilterBar(
      new FetchableData<Array<Label>>({
        abortController: null,
        state: FetchableDataState.LOADING,
        data: null,
        error: null,
      }),
    ),
  );
  try {
    const labels: OrganisationLabelsResponse = yield call(
      ProductMonitor.endpoints.me.organisation.labels.all.call.bind(
        ProductMonitor.endpoints.me.organisation.labels.all,
      ),
      {},
    );

    yield put(
      updateLabelsFilterBar(
        new FetchableData<Array<Label>>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: mapLabelRawToLabel(labels.post),
          error: null,
        }),
      ),
    );
    yield put(
      updateImageLabelsFilterBar(
        new FetchableData<Array<Label>>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: mapLabelRawToLabel(labels.image),
          error: null,
        }),
      ),
    );
    yield put(
      updateAccountLabelsFilterBar(
        new FetchableData<Array<Label>>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: mapLabelRawToLabel(labels.account),
          error: null,
        }),
      ),
    );
  } catch (err: any) {
    yield put(
      updateLabelsFilterBar(
        new FetchableData<Array<Label>>({
          abortController: null,
          state: FetchableDataState.ERROR,
          data: null,
          error: new CustomError(err.message),
        }),
      ),
    );
    yield put(
      updateImageLabelsFilterBar(
        new FetchableData<Array<Label>>({
          abortController: null,
          state: FetchableDataState.ERROR,
          data: null,
          error: new CustomError(err.message),
        }),
      ),
    );
    yield put(
      updateAccountLabelsFilterBar(
        new FetchableData<Array<Label>>({
          abortController: null,
          state: FetchableDataState.ERROR,
          data: null,
          error: new CustomError(err.message),
        }),
      ),
    );
  }
}

export function* loadFilters() {
  try {
    const filtersPost: Array<SavedFilterRaw> = yield call(
      ProductMonitor.endpoints.me.filterPresets.call.bind(
        ProductMonitor.endpoints.me.filterPresets,
      ),
      {
        params: {
          filter_type: 'post',
        },
      },
    );
    const filtersImage: Array<SavedFilterRaw> = yield call(
      ProductMonitor.endpoints.me.filterPresets.call.bind(
        ProductMonitor.endpoints.me.filterPresets,
      ),
      {
        params: {
          filter_type: 'image',
        },
      },
    );
    const filtersAccounts: Array<SavedFilterRaw> = yield call(
      ProductMonitor.endpoints.me.filterPresets.call.bind(
        ProductMonitor.endpoints.me.filterPresets,
      ),
      {
        params: {
          filter_type: 'account',
        },
      },
    );
    const filtersWebsites: Array<SavedFilterRaw> = yield call(
      ProductMonitor.endpoints.me.filterPresets.call.bind(
        ProductMonitor.endpoints.me.filterPresets,
      ),
      {
        params: {
          filter_type: 'website',
        },
      },
    );

    yield put(
      loadFilterSuccessAction({
        account: filtersAccounts.map(SavedFilterModel.fromRawModel),
        image: filtersImage.map(SavedFilterModel.fromRawModel),
        post: filtersPost.map(SavedFilterModel.fromRawModel),
        website: filtersWebsites.map(SavedFilterModel.fromRawModel),
      }),
    );
  } catch (err) {
    console.error(err);
  }
}

export function* saveFilter(action: ReturnType<typeof saveFilterAction>) {
  try {
    yield call(
      ProductMonitor.endpoints.me.saveFilter.call.bind(
        ProductMonitor.endpoints.me.saveFilter,
      ),
      {
        data: action.data,
        suppressToastrOnError: true,
      },
    );

    yield put(loadFiltersAction());
    action.setShowSaveFilterModal(false);
    action.setErrorMessage('');
    notification.success({
      message: 'Filter was created successfully.',
      placement: 'bottomRight',
      duration: 5,
    });
  } catch (err) {
    console.error(err);
    const error = CustomErrorFactory.create(err);
    action.setErrorMessage(error.message);
  }
}

export function* deleteFilter(action) {
  try {
    yield call(
      ProductMonitor.endpoints.me.deleteFilter.call.bind(
        ProductMonitor.endpoints.me.deleteFilter,
      ),
      { data: action.data },
    );
    yield put(loadFiltersAction());
    notification.success({
      message: 'Filter was removed successfully.',
      placement: 'bottomRight',
      duration: 5,
    });
  } catch (err) {
    console.error(err);
    notification.error({
      message: CustomErrorFactory.create(err).message,
      placement: 'bottomRight',
      duration: 5,
    });
  }
}

// HERE ARE FINAL LOADER SAGA OTHERS WILL BE REMOVED IN NEXT COMMITS

function* loadFiltersBarTags(action) {
  const tagType = action.tagType === 'website' ? 'account' : action.tagType;

  yield put(
    updateFilterBarTags(
      tagType,
      new FetchableData<Array<TagModel>>({
        abortController: null,
        state: FetchableDataState.LOADING,
        data: null,
        error: null,
      }),
    ),
  );
  try {
    const result: TagsReasonResponse = yield call(
      ProductMonitor.endpoints.me.organisation.tags.call.bind(
        ProductMonitor.endpoints.me.organisation.tags,
      ),
      {
        urlParams: { tagType },
      },
    );

    yield put(
      updateFilterBarTags(
        tagType,
        new FetchableData<Array<TagModel>>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: result.map((t) => TagModel.createFromRawModel(t)),
          error: null,
        }),
      ),
    );
  } catch (err: any) {
    yield put(
      updateFilterBarTags(
        tagType,
        new FetchableData<Array<TagModel>>({
          abortController: null,
          state: FetchableDataState.ERROR,
          data: null,
          error: new CustomError(err.message),
        }),
      ),
    );
  }
}

function* appendNonTrackedWebsites(initialWebsites: TopWebsites) {
  const resultWebsites: Array<WebsiteSuggestion> = [];

  resultWebsites.push(
    ...initialWebsites.map((website) => ({
      count: website.count,
      label: website.label,
      id: website.value,
    })),
  );

  const includedWebsites = new Set(
    Object.values(resultWebsites.map((v) => v.id)),
  );

  const urlParams = new URLSearchParams(window.location.search);
  const websiteInclude = urlParams.getAll('website_id');
  const websiteExclude = urlParams.getAll('website_id_to_exclude');

  const nonTrackedWebsiteList = [
    ...websiteInclude.filter(
      (searchedWebsiteId) => !includedWebsites.has(searchedWebsiteId),
    ),
    ...websiteExclude.filter(
      (searchedWebsiteId) => !includedWebsites.has(searchedWebsiteId),
    ),
  ];

  let resultAddition: WebsiteNamesResponse = { results: [] };
  if (nonTrackedWebsiteList.length) {
    resultAddition = yield call(
      ProductMonitor.endpoints.me.websiteNames.call.bind(
        ProductMonitor.endpoints.me.websiteNames,
      ),
      {
        params: {
          ids: nonTrackedWebsiteList,
        },
      },
    );
  }

  resultWebsites.push(
    ...resultAddition.results.map((website) => ({
      count: 0,
      label: website.label,
      id: website.value,
    })),
  );

  return resultWebsites;
}

function* loadGlobalData() {
  yield put(
    updateInsights(
      new FetchableData<Array<Insight>>({
        abortController: null,
        state: FetchableDataState.LOADING,
        data: null,
        error: null,
      }),
    ),
  );
  yield put(
    updateLabelsFilterBar(
      new FetchableData<Array<Label>>({
        abortController: null,
        state: FetchableDataState.LOADING,
        data: null,
        error: null,
      }),
    ),
  );
  yield put(
    updateAvailableContactTypes(
      new FetchableData<Array<ContactMainInfo>>({
        abortController: null,
        state: FetchableDataState.LOADING,
        data: null,
        error: null,
      }),
    ),
  );
  yield put(
    updateImageLabelsFilterBar(
      new FetchableData<Array<Label>>({
        abortController: null,
        state: FetchableDataState.LOADING,
        data: null,
        error: null,
      }),
    ),
  );
  yield put(
    updateAccountLabelsFilterBar(
      new FetchableData<Array<Label>>({
        abortController: null,
        state: FetchableDataState.LOADING,
        data: null,
        error: null,
      }),
    ),
  );
  yield put(
    updateFiltersBarWebsites(
      new FetchableData<Array<WebsiteSuggestion>>({
        abortController: null,
        state: FetchableDataState.LOADING,
        data: null,
        error: null,
      }),
    ),
  );
  yield put(
    updateCategoriesFilterBar(
      new FetchableData<ProductCategoryTree>({
        abortController: null,
        state: FetchableDataState.LOADING,
        data: null,
        error: null,
      }),
    ),
  );
  yield put(
    updateFeatureLabelesOptions(
      new FetchableData<Array<ModerationReason>>({
        abortController: null,
        state: FetchableDataState.LOADING,
        data: null,
        error: null,
      }),
    ),
  );
  yield put(
    updateUsers(
      new FetchableData<Array<UserLightModel>>({
        abortController: null,
        state: FetchableDataState.LOADING,
        data: [],
        error: null,
      }),
    ),
  );
  yield put(
    updateFiltersBarWebsiteCategoriesAction(
      new FetchableData<Array<WebsiteCategory>>({
        abortController: null,
        state: FetchableDataState.LOADING,
        data: null,
        error: null,
      }),
    ),
  );
  const tagTypes = [
    TagTypeEnum.post,
    TagTypeEnum.account,
    TagTypeEnum.duplicatedGroup,
    TagTypeEnum.vendor,
    TagTypeEnum.uploadHistory,
  ];

  for (let i = 0; i < tagTypes.length; i += 1) {
    const tagType = tagTypes[i];
    yield put(
      updateFilterBarTags(
        tagType,
        new FetchableData<Array<TagModel>>({
          abortController: null,
          state: FetchableDataState.LOADING,
          data: null,
          error: null,
        }),
      ),
    );
  }

  yield put(
    updateFiltersBarGeography(
      new FetchableData<GeographySelectOptions>({
        abortController: null,
        state: FetchableDataState.LOADING,
        data: null,
        error: null,
      }),
    ),
  );

  yield put(
    updatePostExportTemplates(
      new FetchableData<Array<ExportTemplateModel>>({
        abortController: null,
        state: FetchableDataState.LOADING,
        data: null,
        error: null,
      }),
    ),
  );

  yield put(
    updateScrappingSource(
      new FetchableData<Array<ScrappingSource>>({
        abortController: null,
        data: null,
        state: FetchableDataState.LOADED,
        error: null,
      }),
    ),
  );

  try {
    let response: GlobalDataResponse | null = null;
    const element = document.querySelector('[name="cachedRequest.mainInfo"]');
    if (element) {
      if (
        /* eslint-disable-next-line no-template-curly-in-string */
        element.getAttribute('content') !== '${mainInfo}' &&
        element.getAttribute('content') !== 'null'
      ) {
        response = base64ToJson(element.getAttribute('content'));
      }
      element.remove();
    }
    if (!response) {
      response = yield call(
        ProductMonitor.endpoints.me.globalData.call.bind(
          ProductMonitor.endpoints.me.globalData,
        ),
        {},
      );
    }
    response = response as GlobalDataResponse;
    const topWebsites = yield call(
      appendNonTrackedWebsites,
      response.top_websites,
    );

    yield put(
      updateAvailableContactTypes(
        new FetchableData<Array<ContactMainInfo>>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: response.contact_types,
          error: null,
        }),
      ),
    );

    yield put(
      updateFiltersBarWebsites(
        new FetchableData<Array<WebsiteSuggestion>>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: topWebsites,
          error: null,
        }),
      ),
    );

    yield put(
      updateCurrencies(
        new FetchableData<Array<Currency>>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: response.currencies,
          error: null,
        }),
      ),
    );

    yield put(
      updateAvailableLanguages(
        new FetchableData<Array<LanguageModel>>({
          abortController: null,
          data: response.languages.map(LanguageModel.fromRaw),
          state: FetchableDataState.LOADED,
          error: null,
        }),
      ),
    );

    yield put(
      updateScrappingSource(
        new FetchableData<Array<ScrappingSource>>({
          abortController: null,
          data: response.scraping_sources,
          state: FetchableDataState.LOADED,
          error: null,
        }),
      ),
    );

    yield put(
      updateInsights(
        new FetchableData<Array<Insight>>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: response.insight_options,
          error: null,
        }),
      ),
    );

    yield put(
      updateLabelsFilterBar(
        new FetchableData<Array<Label>>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: mapLabelRawToLabel(response.labels.post),
          error: null,
        }),
      ),
    );
    yield put(
      updateImageLabelsFilterBar(
        new FetchableData<Array<Label>>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: mapLabelRawToLabel(response.labels.image),
          error: null,
        }),
      ),
    );
    yield put(
      updateAccountLabelsFilterBar(
        new FetchableData<Array<Label>>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: mapLabelRawToLabel(response.labels.account),
          error: null,
        }),
      ),
    );

    yield put(
      updateCategoriesFilterBar(
        new FetchableData<ProductCategoryTree>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: ProductCategoryTree.createFromRawModel(response.categories),
          error: null,
        }),
      ),
    );
    yield put(
      updateFeatureLabelesOptions(
        new FetchableData<Array<ModerationReason>>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: response.moderation_reasons.map(
            ModerationReason.createReasonFromRaw,
          ),
          error: null,
        }),
      ),
    );
    yield put(
      updateUsers(
        new FetchableData<Array<UserLightModel>>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: response.users.map(UserLightModel.createFromRawModel),
          error: null,
        }),
      ),
    );
    yield put(
      updateRoles(
        new FetchableData<Array<RoleModel>>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: response.roles.map(RoleModel.createFromRoleRaw),
          error: null,
        }),
      ),
    );
    yield put(
      loadFilterSuccessAction({
        account: response.filter_presets.account.map(
          SavedFilterModel.fromRawModel,
        ),
        image: response.filter_presets.image.map(SavedFilterModel.fromRawModel),
        post: response.filter_presets.post.map(SavedFilterModel.fromRawModel),
        website: response.filter_presets.website.map(
          SavedFilterModel.fromRawModel,
        ),
      }),
    );
    yield put(
      updateFiltersBarWebsiteCategoriesAction(
        new FetchableData<Array<WebsiteCategory>>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: response.website_categories,
          error: null,
        }),
      ),
    );
    for (let i = 0; i < tagTypes.length; i += 1) {
      const tagType = tagTypes[i];
      yield put(
        updateFilterBarTags(
          tagType,
          new FetchableData<Array<TagModel>>({
            abortController: null,
            state: FetchableDataState.LOADED,
            data: response.tags[tagType].map(TagModel.createFromRawModel),
            error: null,
          }),
        ),
      );
    }

    const countryMap = (response.geography ?? []).reduce(
      (acc, country) => {
        acc[country.country_code] = CountryToGeo(country);
        return acc;
      },
      {} as { [key: string]: Geographic },
    );
    const countries = Object.values(countryMap);
    yield put(
      updateFiltersBarGeography(
        new FetchableData<GeographySelectOptions>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: {
            countries,
            zones: response.geography_zones.map(ZoneToGeo),
          },
          error: null,
        }),
      ),
    );

    yield put(
      updateCrawlingDomain(
        new FetchableData<Array<CrawlingDomain>>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: response.domains_available_for_scraping.map((domain) =>
            CrawlingDomain.createFromRawModel(domain, countryMap),
          ),
          error: null,
        }),
      ),
    );

    yield put(
      setOrganisationStatuses(
        new FetchableData<Array<OrganisationStatus>>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: Object.keys(response.organisation_statuses).map((key) => ({
            label: key,
            value: response.organisation_statuses[key],
          })),
          error: null,
        }),
      ),
    );

    yield put(
      updatePostExportTemplates(
        new FetchableData<Array<ExportTemplateModel>>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: response.posts_export_templates.map(
            ExportTemplateModel.createFromRaw,
          ),
          error: null,
        }),
      ),
    );
  } catch (err: any) {
    console.error('err', err);

    yield put(
      updateScrappingSource(
        new FetchableData<Array<ScrappingSource>>({
          abortController: null,
          state: FetchableDataState.ERROR,
          error: new CustomError(err.message),
          data: null,
        }),
      ),
    );

    yield put(
      updateInsights(
        new FetchableData<Array<Insight>>({
          abortController: null,
          state: FetchableDataState.ERROR,
          error: new CustomError(err.message),
          data: null,
        }),
      ),
    );
    yield put(
      updateAvailableContactTypes(
        new FetchableData<Array<ContactMainInfo>>({
          abortController: null,
          state: FetchableDataState.ERROR,
          error: new CustomError(err.message),
          data: null,
        }),
      ),
    );
    yield put(
      updateFiltersBarWebsites(
        new FetchableData<Array<WebsiteSuggestion>>({
          abortController: null,
          state: FetchableDataState.ERROR,
          data: null,
          error: new CustomError(err.message),
        }),
      ),
    );
    yield put(
      updateLabelsFilterBar(
        new FetchableData<Array<Label>>({
          abortController: null,
          state: FetchableDataState.ERROR,
          data: null,
          error: new CustomError(err.message),
        }),
      ),
    );
    yield put(
      updateImageLabelsFilterBar(
        new FetchableData<Array<Label>>({
          abortController: null,
          state: FetchableDataState.ERROR,
          data: null,
          error: new CustomError(err.message),
        }),
      ),
    );
    yield put(
      updateAccountLabelsFilterBar(
        new FetchableData<Array<Label>>({
          abortController: null,
          state: FetchableDataState.ERROR,
          data: null,
          error: new CustomError(err.message),
        }),
      ),
    );
    yield put(
      updateCategoriesFilterBar(
        new FetchableData<ProductCategoryTree>({
          abortController: null,
          state: FetchableDataState.ERROR,
          data: null,
          error: new CustomError(err.message),
        }),
      ),
    );
    yield put(
      updateFeatureLabelesOptions(
        new FetchableData<Array<ModerationReason>>({
          abortController: null,
          state: FetchableDataState.ERROR,
          data: null,
          error: new CustomError(err.message),
        }),
      ),
    );
    yield put(
      updateUsers(
        new FetchableData<Array<UserLightModel>>({
          abortController: null,
          state: FetchableDataState.ERROR,
          data: [],
          error: new CustomError(err.message),
        }),
      ),
    );
    yield put(
      updateFiltersBarWebsiteCategoriesAction(
        new FetchableData<Array<WebsiteCategory>>({
          abortController: null,
          state: FetchableDataState.ERROR,
          data: null,
          error: new CustomError(err.message),
        }),
      ),
    );
    for (let i = 0; i < tagTypes.length; i += 1) {
      const tagType = tagTypes[i];
      yield put(
        updateFilterBarTags(
          tagType,
          new FetchableData<Array<TagModel>>({
            abortController: null,
            state: FetchableDataState.ERROR,
            data: null,
            error: new CustomError(err.message),
          }),
        ),
      );
    }
    yield put(
      updateFiltersBarGeography(
        new FetchableData<GeographySelectOptions>({
          abortController: null,
          state: FetchableDataState.ERROR,
          data: null,
          error: new CustomError(err.message),
        }),
      ),
    );
    yield put(
      updatePostExportTemplates(
        new FetchableData<Array<ExportTemplateModel>>({
          abortController: null,
          state: FetchableDataState.ERROR,
          data: null,
          error: new CustomError(err.message),
        }),
      ),
    );
    yield put(
      updateCrawlingDomain(
        new FetchableData<Array<CrawlingDomain>>({
          abortController: null,
          state: FetchableDataState.ERROR,
          data: null,
          error: new CustomError(err.message),
        }),
      ),
    );
  }
}

function* loadUsers() {
  yield put(
    updateUsers(
      new FetchableData<Array<UserLightModel>>({
        abortController: null,
        state: FetchableDataState.LOADING,
        data: [],
        error: null,
      }),
    ),
  );
  try {
    const result: UsersResponse = yield call(
      ProductMonitor.endpoints.users.getAll.call.bind(
        ProductMonitor.endpoints.users.getAll,
      ),
      {},
    );
    yield put(
      updateUsers(
        new FetchableData<Array<UserLightModel>>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: result.map(UserLightModel.createFromRawModel),
          error: null,
        }),
      ),
    );
  } catch (err: any) {
    yield put(
      updateUsers(
        new FetchableData<Array<UserLightModel>>({
          abortController: null,
          state: FetchableDataState.ERROR,
          data: [],
          error: new CustomError(err.message),
        }),
      ),
    );
  }
}

function* loadImageForSearch(action: LoadImageForSearchProps) {
  const formData = new FormData();
  const {
    searchItem,
    searchItem: { additionalInfo },
  } = action;
  formData.append(
    'image_url',
    (additionalInfo as DuplicatedGroupSearchItemModelAdditionalInfo).url,
  );
  try {
    const image: SearchImagesResponse = yield call(
      ProductMonitor.endpoints.images.searchImages.call.bind(
        ProductMonitor.endpoints.images.searchImages,
      ),
      { data: formData },
    );
    yield put(
      updateImageForSearch({
        searchFilterUUID: action.searchFilterUUID,
        searchItem: new SearchItemModel({
          ...searchItem,
          value: `${image.duplicated_group_id ?? ''}`,
          additionalInfo: {
            fuid: (
              additionalInfo as DuplicatedGroupSearchItemModelAdditionalInfo
            ).url,
            id: image.duplicated_group_id,
            image_found: image.image_found,
            status: FetchableDataState.LOADED,
            url: image.image_link,
          } as DuplicatedGroupSearchItemModelAdditionalInfo,
        }),
      }),
    );
  } catch (err) {
    yield put(
      updateImageForSearch({
        searchFilterUUID: action.searchFilterUUID,
        searchItem: new SearchItemModel({
          ...searchItem,
          additionalInfo: {
            fuid: (
              additionalInfo as DuplicatedGroupSearchItemModelAdditionalInfo
            ).url,
            id: '',
            image_found: false,
            status: FetchableDataState.ERROR,
            url: (
              additionalInfo as DuplicatedGroupSearchItemModelAdditionalInfo
            ).url,
          } as DuplicatedGroupSearchItemModelAdditionalInfo,
        }),
      }),
    );
  }
}

function* loadImageByFileForSearch(action: LoadImageForSearchProps) {
  const formData = new FormData();
  const {
    searchItem,
    searchItem: { additionalInfo },
  } = action;
  formData.append(
    'image_file',
    (additionalInfo as DuplicatedGroupSearchItemModelAdditionalInfo).fuid,
  );
  try {
    const image: SearchImagesResponse = yield call(
      ProductMonitor.endpoints.images.searchImages.call.bind(
        ProductMonitor.endpoints.images.searchImages,
      ),
      { data: formData },
    );
    yield put(
      updateImageForSearch({
        searchFilterUUID: action.searchFilterUUID,
        searchItem: new SearchItemModel({
          ...searchItem,
          value: `${image.duplicated_group_id ?? ''}`,
          additionalInfo: {
            fuid: (
              additionalInfo as DuplicatedGroupSearchItemModelAdditionalInfo
            ).url,
            id: image.duplicated_group_id,
            image_found: image.image_found,
            status: FetchableDataState.LOADED,
            url: image.image_link,
          } as DuplicatedGroupSearchItemModelAdditionalInfo,
        }),
      }),
    );
  } catch (err) {
    yield put(
      updateImageForSearch({
        searchFilterUUID: action.searchFilterUUID,
        searchItem: new SearchItemModel({
          ...searchItem,
          additionalInfo: {
            fuid: (
              additionalInfo as DuplicatedGroupSearchItemModelAdditionalInfo
            ).url,
            id: '',
            image_found: false,
            status: FetchableDataState.ERROR,
            url: (
              additionalInfo as DuplicatedGroupSearchItemModelAdditionalInfo
            ).url,
          } as DuplicatedGroupSearchItemModelAdditionalInfo,
        }),
      }),
    );
  }
}

function* loadImageByIdForSearch(action: LoadImageForSearchProps) {
  const {
    searchItem,
    searchItem: { additionalInfo },
  } = action;
  try {
    const image: ImageModerationRawModel = yield call(
      ProductMonitor.endpoints.images.getImage.call.bind(
        ProductMonitor.endpoints.images.getImage,
      ),
      {
        signal: action.abortController?.signal,
        urlParams: {
          id: searchItem.value as string,
        },
      },
    );
    yield put(
      updateImageForSearch({
        searchFilterUUID: action.searchFilterUUID,
        searchItem: new SearchItemModel({
          ...searchItem,
          additionalInfo: {
            fuid: (
              additionalInfo as DuplicatedGroupSearchItemModelAdditionalInfo
            ).url,
            id: image.id,
            image_found: true,
            status: FetchableDataState.LOADED,
            url: image.image_url,
          } as DuplicatedGroupSearchItemModelAdditionalInfo,
        }),
      }),
    );
  } catch (err: any) {
    yield put(
      updateImageForSearch({
        searchFilterUUID: action.searchFilterUUID,
        searchItem: new SearchItemModel({
          ...searchItem,
          additionalInfo: {
            fuid: (
              additionalInfo as DuplicatedGroupSearchItemModelAdditionalInfo
            ).url,
            id: '',
            image_found: false,
            status:
              err?.name === 'NotFoundError'
                ? FetchableDataState.NOT_FOUND
                : FetchableDataState.ERROR,
            url: (
              additionalInfo as DuplicatedGroupSearchItemModelAdditionalInfo
            ).url,
          } as DuplicatedGroupSearchItemModelAdditionalInfo,
        }),
      }),
    );
  }
}

function* loadSearchItemsData({
  filter,
  abortController,
}: ReturnType<typeof loadSearchItemsDataAction>) {
  try {
    const searchFilter = filter.searchFilter as SearchFilter;
    yield all(
      searchFilter.value.values
        .map((searchItem) => {
          if (searchItem.option === SearchBarType.duplicatedGroupId) {
            if (
              searchItem.additionalInfo &&
              (
                searchItem.additionalInfo as DuplicatedGroupSearchItemModelAdditionalInfo
              ).status === FetchableDataState.LOADING
            ) {
              return put(
                loadImageByIdForSearchAction({
                  searchFilterUUID: filter.uuid,
                  searchItem,
                  abortController,
                }),
              );
            }
          }
          return null;
        })
        .filter(Boolean),
    );
  } catch (err) {
    console.error(err);
  }
}

function* loadAdvancedStatistics() {
  yield put(
    updateAdvancedStatistics(
      new FetchableData<AdvancedStatistics>({
        abortController: null,
        state: FetchableDataState.LOADING,
        data: null,
        error: null,
      }),
    ),
  );
  try {
    const feed: FeedFilterModel = yield select(
      (state: AppState) => state.filters_bar.feed,
    );
    const advancedStatistics: ResponseAdvancedStatistics = yield call(
      ProductMonitor.endpoints.me.advancedStats.call.bind(
        ProductMonitor.endpoints.me.advancedStats,
      ),
      {
        data: RequestParamsAdvancedStatistics.createFromQuery(feed),
        suppressToastrOnError: true,
      },
    );

    yield put(
      updateAdvancedStatistics(
        new FetchableData<AdvancedStatistics>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: new AdvancedStatistics({
            accounts: advancedStatistics.accounts.data,
            images: advancedStatistics.images.data,
            posts: advancedStatistics.posts.data,
            websites: advancedStatistics.websites.data,
            accountsFromContacts:
              advancedStatistics.accounts_from_contacts.data.map(
                (accountFromContact) => snakeToCamelNested(accountFromContact),
              ),
            extractedContacts: advancedStatistics.extracted_contacts.map(
              (extractedContact) => snakeToCamelNested(extractedContact),
            ),
          }),
          error: null,
        }),
      ),
    );
  } catch (err: any) {
    notification.error({
      message: `Error when loading Advanced search data: ${
        (err as any).message
      }`,
      placement: 'bottomRight',
      duration: 5,
    });
    yield put(
      updateAdvancedStatistics(
        new FetchableData<AdvancedStatistics>({
          abortController: null,
          state: FetchableDataState.ERROR,
          data: null,
          error: new CustomError(err.message),
        }),
      ),
    );
  }
}

// Individual exports for testing
export default function* filtersBarSaga() {
  yield takeEvery(LOAD_LABELS, loadLabels);
  yield takeLatest(LOAD_SAVED_FILTERS, loadFilters);
  yield takeLatest(SAVE_FILTER, saveFilter);
  yield takeLatest(DELETE_FILTER, deleteFilter);
  yield takeLatest(LOAD_GLOBAL_DATA, loadGlobalData);
  yield takeEvery(LOAD_FILTERS_BAR_TAGS, loadFiltersBarTags);
  yield takeLatest(LOAD_USERS, loadUsers);
  yield takeEvery(LOAD_SEARCH_ITEMS_DATA, loadSearchItemsData);
  yield takeEvery(LOAD_IMAGE_FOR_SEARCH, loadImageForSearch);
  yield takeEvery(LOAD_IMAGE_BY_FILE_FOR_SEARCH, loadImageByFileForSearch);
  yield takeEvery(LOAD_IMAGE_BY_ID_FOR_SEARCH, loadImageByIdForSearch);
  yield takeEvery(LOAD_ADVANCED_STATISTICS, loadAdvancedStatistics);
}
