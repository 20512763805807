import React from 'react';
import Typography from 'product-ui/src/components/atoms/Typography';
import NaveeIcon from 'product-ui/src/components/atoms/NaveeIcon/NaveeIcon';
import Tag from 'product-ui/src/components/atoms/Tag';
import {
  DisplayFilterComponent,
  DisplayFilterComponentProps,
  FilterTypeEnum,
} from '../../../types/filters/AtomicFilters/Filter';

const tagPrefixMap = {
  [FilterTypeEnum.postTagsInclude]: '',
  [FilterTypeEnum.postTagsExclude]: '',
  [FilterTypeEnum.duplicatedGroupTagsInclude]: 'Image',
  [FilterTypeEnum.duplicatedGroupTagsExclude]: 'Image',
  [FilterTypeEnum.accountTagsInclude]: 'Account',
  [FilterTypeEnum.accountTagsExclude]: 'Account',
  [FilterTypeEnum.vendorrTagsInclude]: 'Cluster',
  [FilterTypeEnum.vendorTagsExclude]: 'Cluster',
  [FilterTypeEnum.uploadTagsToInclude]: 'Upload',
};

export const NewTagsFilterValue: DisplayFilterComponent = ({
  filterValue: { uuid, name, value },
  onClickRemove,
}: DisplayFilterComponentProps) => {
  const excluded = [
    FilterTypeEnum.postTagsExclude,
    FilterTypeEnum.accountTagsExclude,
    FilterTypeEnum.duplicatedGroupTagsExclude,
    FilterTypeEnum.vendorTagsExclude,
  ];
  const feedTagsTag = (
    <Tag
      tag={{
        name: tagPrefixMap[name]
          ? `[${tagPrefixMap[name]}] ${value.name}`
          : value.name,
      }}
      key={uuid}
      onClickRemove={() => {
        onClickRemove();
      }}
      excluded={excluded.includes(name)}
      iconPrefix={<NaveeIcon.Tags />}
    />
  );
  const postTagsSearchTag = (
    <Tag
      tag={{
        name: value.name,
      }}
      key={uuid}
      label={
        <>
          Post tag:{' '}
          <Typography tag="span" fontWeight="thin">
            %
          </Typography>
          {` ${value.name} `}
          <Typography tag="span" fontWeight="thin">
            %
          </Typography>
        </>
      }
      onClickRemove={() => {
        onClickRemove();
      }}
      tooltipText={`Post tag contains: ${value.name}`}
      excluded={FilterTypeEnum.postTagsExcludeSearch === name}
      iconPrefix={<NaveeIcon.Tags />}
    />
  );
  const duplicatedGroupTagsSearchTag = (
    <Tag
      tag={{
        name: value.name,
      }}
      key={uuid}
      label={
        <>
          Image tag:{' '}
          <Typography tag="span" fontWeight="thin">
            %
          </Typography>
          {` ${value.name} `}
          <Typography tag="span" fontWeight="thin">
            %
          </Typography>
        </>
      }
      onClickRemove={() => {
        onClickRemove();
      }}
      tooltipText={`Image tag contains: ${value.name}`}
      excluded={FilterTypeEnum.duplicatedGroupTagsExcludeSearch === name}
      iconPrefix={<NaveeIcon.Tags />}
    />
  );
  const accountTagsSearchTag = (
    <Tag
      tag={{
        name: value.name,
      }}
      label={
        <>
          Account tag:{' '}
          <Typography tag="span" fontWeight="thin">
            %
          </Typography>
          {` ${value.name} `}
          <Typography tag="span" fontWeight="thin">
            %
          </Typography>
        </>
      }
      tooltipText={`Account tag contains: ${value.name}`}
      key={uuid}
      onClickRemove={() => {
        onClickRemove();
      }}
      excluded={FilterTypeEnum.accountTagsExcludeSearch === name}
      iconPrefix={<NaveeIcon.Tags />}
    />
  );
  const vendorTagsSearchTag = (
    <Tag
      tag={{
        name: value.name,
      }}
      label={
        <>
          Vendor tag:{' '}
          <Typography tag="span" fontWeight="thin">
            %
          </Typography>
          {` ${value.name} `}
          <Typography tag="span" fontWeight="thin">
            %
          </Typography>
        </>
      }
      tooltipText={`Cluster tag contains: ${value.name}`}
      key={uuid}
      onClickRemove={() => {
        onClickRemove();
      }}
      excluded={FilterTypeEnum.vendorTagsExcludeSearch === name}
      iconPrefix={<NaveeIcon.Tags />}
    />
  );
  const filterTypeTagMap = {
    [FilterTypeEnum.postTagsInclude]: feedTagsTag,
    [FilterTypeEnum.postTagsExclude]: feedTagsTag,
    [FilterTypeEnum.accountTagsInclude]: feedTagsTag,
    [FilterTypeEnum.duplicatedGroupTagsInclude]: feedTagsTag,
    [FilterTypeEnum.vendorrTagsInclude]: feedTagsTag,
    [FilterTypeEnum.uploadTagsToInclude]: feedTagsTag,
    [FilterTypeEnum.accountTagsExclude]: feedTagsTag,
    [FilterTypeEnum.duplicatedGroupTagsExclude]: feedTagsTag,
    [FilterTypeEnum.vendorTagsExclude]: feedTagsTag,
    [FilterTypeEnum.postTagsIncludeSearch]: postTagsSearchTag,
    [FilterTypeEnum.postTagsExcludeSearch]: postTagsSearchTag,
    [FilterTypeEnum.duplicatedGroupTagsIncludeSearch]:
      duplicatedGroupTagsSearchTag,
    [FilterTypeEnum.duplicatedGroupTagsExcludeSearch]:
      duplicatedGroupTagsSearchTag,
    [FilterTypeEnum.accountTagsIncludeSearch]: accountTagsSearchTag,
    [FilterTypeEnum.accountTagsExcludeSearch]: accountTagsSearchTag,
    [FilterTypeEnum.vendorrTagsIncludeSearch]: vendorTagsSearchTag,
    [FilterTypeEnum.vendorTagsExcludeSearch]: vendorTagsSearchTag,
  };

  return filterTypeTagMap[name] || null;
};
