import React from 'react';
import { ConfigProvider, ThemeConfig } from 'antd';
import HasChildren from 'product-ui/src/components/atoms/Interfaces/HasChildren';

export const defaultThemeConfig: ThemeConfig = {
  components: {
    Input: {
      fontFamily: 'Satoshi',
      activeBorderColor: 'var(--primary-green)',
      fontSize: 14,
      fontSizeLG: 16,
      hoverBorderColor: 'var(--neutral-grey-700)',
      paddingBlock: 12,
      controlHeightLG: 40,
      paddingInline: 12,
      paddingBlockLG: 11,
      paddingInlineLG: 11,
      activeShadow:
        '0px -1px 1px 0px rgba(218, 227, 225, 0.15) inset, 0px 2px 2px 0px rgba(218, 227, 225, 0.10)',
      errorActiveShadow: '',
    },
    InputNumber: {
      fontFamily: 'Satoshi',
      fontSize: 14,
      fontSizeLG: 16,
      activeBorderColor: 'var(--primary-green)',
      hoverBorderColor: 'var(--neutral-grey-700)',
      paddingBlock: 12,
      paddingInline: 12,
      paddingBlockLG: 11,
      paddingInlineLG: 11,
      activeShadow:
        '0px -1px 1px 0px rgba(218, 227, 225, 0.15) inset, 0px 2px 2px 0px rgba(218, 227, 225, 0.10)',
      errorActiveShadow: '',
    },
    Pagination: {
      itemBg: 'var(--white)',
      controlHeight: 32,
      controlHeightSM: 32,
      controlHeightLG: 32,
      controlHeightXS: 32,
      itemActiveBg: 'var(--primary-green)',
      controlOutlineWidth: 1,
      controlOutline: 'var(--primary-border)',
      colorPrimary: 'var(--neutral-grey-700)',
      colorPrimaryHover: 'var(--primary-grey-700)',
      colorBorderSecondary: 'var(--primary-border)',
      colorBorder: 'var(--primary-border)',
      lineType: 'solid',
      lineWidth: 1,
    },
    Checkbox: {
      colorPrimaryHover: 'var(--primary-green)',
      colorBorder: 'var(--primary-border)',
      colorPrimary: 'var(--primary-green)',
      colorPrimaryBorder: 'var(--primary-border)',
      colorWhite: 'var(--primary-black)',
      controlInteractiveSize: 24,
      borderRadius: 8,
      borderRadiusLG: 8,
      borderRadiusSM: 8,
      borderRadiusXS: 8,
      fontFamily: 'Satoshi',
      fontSize: 14,
      lineHeight: 1.428571428571429,
      paddingContentVertical: 8,
      paddingContentHorizontal: 4,
      margin: 8,
    },
    Select: {
      optionPadding: '0.75em 1em',
      optionActiveBg: 'var(--primary-hover)',
      optionSelectedBg: 'var(--primary-green)',
      paddingContentVertical: 12,
      paddingContentHorizontal: 16,
      paddingContentVerticalSM: 12,
      paddingContentHorizontalSM: 16,
      paddingContentVerticalLG: 12,
      paddingContentHorizontalLG: 16,
      borderRadius: 4,
      controlHeight: 48,
      controlHeightLG: 48,
      controlHeightSM: 40,
      fontSize: 14,
      fontSizeLG: 16,
      optionHeight: 48,
      controlHeightXS: 48,
      singleItemHeightLG: 48,
      colorBorder: 'var(--primary-border)',
      colorPrimary: 'var(--primary-green)',
      colorPrimaryHover: 'var(--primary-black)',
      multipleSelectorBgDisabled: 'var(--neutral-grey-100)',
      colorTextDisabled: 'var(--neutral-grey-400)',
      colorIcon: 'var(--neutral-grey-500)',
      controlPaddingHorizontalSM: 12,
      controlPaddingHorizontal: 12,
    },
    DatePicker: {
      controlHeight: 48,
      cellWidth: 37,
      cellHeight: 32,
      colorTextLightSolid: 'black',
      colorPrimary: 'var(--primary-green)',
      activeBorderColor: 'var(--primary-green)',
      hoverBorderColor: 'var(--primary-black)',
      cellActiveWithRangeBg: 'var(--mint-green-lighter10)',
    },
    Form: {
      itemMarginBottom: 0,
    },
    Tree: {
      nodeHoverBg: 'var(--primary-hover)',
      nodeSelectedBg: 'var(--primary-green)',
      titleHeight: 40,
    },
    Modal: {
      contentBg: 'var(--white)',
      padding: 0,
      paddingLG: 0,
      paddingSM: 0,
      paddingMD: 0,
      paddingXS: 0,
      paddingXL: 0,
      paddingXXS: 0,
      controlPaddingHorizontal: 0,
      paddingContentHorizontal: 0,
      paddingContentHorizontalLG: 0,
      paddingContentHorizontalSM: 0,
      controlPaddingHorizontalSM: 0,
      paddingContentVertical: 0,
      paddingContentVerticalLG: 0,
      paddingContentVerticalSM: 0,
      footerBg: 'var(--neutral-grey-100)',
      borderRadiusOuter: 16,
      borderRadius: 16,
      borderRadiusLG: 16,
      borderRadiusSM: 16,
      borderRadiusXS: 16,
    },
    Tabs: {
      inkBarColor: 'var(--primary-green)',
      itemColor: 'var(--grey-grey-500)',
      itemHoverColor: 'var(--custom-green)',
      itemSelectedColor: 'var(--grey-grey-800)',
      fontFamily: 'Satoshi',
      fontWeightStrong: 700,
      horizontalItemPadding: '20px',
      horizontalItemGutter: 0,
    },
    Menu: {
      itemActiveBg: 'var(--primary-hover)',
      itemSelectedBg: 'var(--primary-hover)',
      itemSelectedColor: 'var(--grey-grey-800)',
    },
    Spin: {
      colorPrimary: '#00f380',
    },
    Table: {
      fontSize: 12,
      fontSizeSM: 12,
      fontSizeLG: 12,
      fontSizeXL: 12,
      borderColor: 'var(--primary-border)',
      fontWeightStrong: 700,
      fontFamily: 'Satoshi',
      colorPrimaryActive: 'var(--primary-green)',
      lineWidth: 0.5,
      colorTextHeading: 'var(--neutral-grey-600)',
      lineHeightLG: 1.5,
      lineHeightSM: 1.5,
      footerBg: 'var(--white)',
      lineHeight: 1.5,
      rowExpandedBg: 'var(--primary-bg)',
      rowHoverBg: 'var(--green-tints-green-1)',
      padding: 16,
    },
    Layout: {
      bodyBg: 'var(--primary-bg)',
    },
    Upload: {
      borderRadiusLG: 10,
      colorBorder: 'var(--green-tints-green-5)',
      colorPrimary: 'var(--primary-green)',
      colorPrimaryHover: 'var(--green-tints-green-7)',
    },
    Tooltip: {
      colorBgSpotlight: 'var(--neutral-grey-800)',
      colorText: 'var(--white)',
      colorTextLightSolid: 'var(--white)',
      zIndexPopup: 10000000,
      borderRadius: 4,
      borderRadiusLG: 4,
      borderRadiusSM: 4,
      borderRadiusOuter: 4,
      borderRadiusXS: 4,
      fontFamily: 'Satoshi',
      sizePopupArrow: 16,
      fontSize: 12,
      lineHeight: 1.5,
    },
    Collapse: {
      headerPadding: '0',
      contentPadding: '0 !important',
    },
  },
  token: {
    borderRadius: 4,
    boxShadow: 'none',
    colorBorder: 'var(--primary-border)',
    fontFamily: 'Satoshi',
  },
};
export const AntdConfigProvider = ({
  children,
  popupContainer,
}: HasChildren & { popupContainer?: HTMLElement }) => (
  <ConfigProvider
    getPopupContainer={popupContainer && (() => popupContainer)}
    theme={defaultThemeConfig}
  >
    {children}
  </ConfigProvider>
);
