/* eslint-disable no-unused-vars */
import React, { useCallback } from 'react';
import { Tabs } from 'product-ui/src/components/atoms/Tabs/Tabs';
import { Flex } from 'antd';
import { FeatureLabelFilter } from '../../../types/filters/AtomicFiltersImplementation/FeatureLabel/FeatureLabelFilter';
import {
  Filter,
  FilterParams,
} from '../../../types/filters/AtomicFilters/Filter';
import FilterWithMenuWrapper from '../FilterWithMenuWrapper';
import { ImageFeatureLabel } from './ImageFeatureLabel';
import { PostFeatureLabel } from './PostFeatureLabel';

export interface NewFeatureLabelFilterProps extends FilterParams {
  onChange: (v: Filter) => void;
  value: FeatureLabelFilter;
}

export const NewFeatureLabelFilter = (props: NewFeatureLabelFilterProps) => {
  const renderer = useCallback(
    () => (
      <Flex
        vertical
        gap="1rem"
        style={{
          width: 284,
          minHeight: 60,
          backgroundColor: 'white',
          boxShadow: '0 4px 16px rgba(0, 0, 0, 0.039)',
        }}
      >
        <Tabs
          defaultActiveKey="image"
          style={{ width: '100%' }}
          animated={false}
          items={[
            {
              key: 'image',
              label: 'IMAGE',
              children: (
                <ImageFeatureLabel
                  onChange={props.onChange}
                  value={props.value}
                />
              ),
            },
            {
              key: 'post',
              label: 'POST',
              children: (
                <PostFeatureLabel
                  onChange={props.onChange}
                  value={props.value}
                />
              ),
            },
          ]}
        />
      </Flex>
    ),
    [props.onChange, props.value],
  );
  return (
    <FilterWithMenuWrapper
      text="Reasons"
      renderer={renderer}
      badgeText={
        props.value.displayingFilterValue.length
          ? props.value.displayingFilterValue.length
          : undefined
      }
    />
  );
};
