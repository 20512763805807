import { HasModerationPage } from 'product-types/src/common/HasModerationPage/HasModerationPage';
import {
  CanBeModeratable,
  Moderatable,
} from 'product-types/src/common/Moderatable/Moderatable';
import dayjs, { Dayjs } from 'dayjs';
import { DATETIME_FORMAT_READABLE } from 'product-utils/src/date';
import { CanBeSelectable } from 'product-types/src/common/CanBeSelectable/CanBeSelectable';
import { Text } from 'product-types/src/domain/text/Text';
import { TagModel, TagRaw } from 'product-types/src/domain/tag/Tag';
import { ItemPrice } from 'product-types/src/domain/price/ItemPrice';
import { AssociatedDuplicatedGroup } from 'product-types/src/domain/duplicatedGroup/AssociatedDuplicatedGroup';
import { Occurrences } from 'product-types/src/domain/occurences/Occurences';
import { Poster, PosterPostFeed } from 'product-types/src/domain/poster/Poster';
import { Contact } from 'product-types/src/domain/contact/Contact';
import { Website } from 'product-types/src/domain/website/Website';
import { Cluster } from 'product-types/src/domain/cluster/Cluster';
import { AssociatedImage } from 'product-types/src/domain/image/AssociatedImage';
import * as Post from './Post';
import {
  ModerationReasonRaw,
  ModerationReason,
  moderationReasonSort,
  SuspiciousPriority,
} from '../moderationReason/ModerationReason';
import { Magnifiable } from '../../common/Magnifiable/Magnifiable';
import { TakenDownable } from '../../common/TakenDownable/TakenDownable';

interface AssociatedImagePostFeed {
  id: number;
  picture_url: string;
  duplicated_group: {
    id: number;
    occurrences: number;
    image_status: string;
    image_status_color: string;
  };
}

interface ContactDetailsRaw {
  contact: string;
  contact_type: string;
  contact_type_key: string;
  id: string;
}

interface WebsitePostFeed {
  category_name: string;
  label_id: number;
  domain_name: string;
  id: number;
}

// todo: fix cluster raw
interface ClusterRaw {
  contact_info: number;
  contact_information: any;
  description: Array<string>;
  estimated_geo: string | null;
  id: number;
  image_link: string;
  infringement_percentage: number;
  moderation_percentage: number;
  name: string;
  post_categories: any | null;
  rank: number;
  total_accounts: null;
  total_posts: number;
  total_product_categories: any | null;
  website_categories_counts: any | null;
}

export interface PostFeedRawModel {
  archive_link: string;
  can_edit_moderation: boolean;
  can_edit_qa_check: boolean;
  can_edit_reasons: boolean;
  can_change_category: boolean;
  can_edit_validation: boolean;
  can_view_qa_check: boolean;
  can_view_validation: boolean;
  global_category_id: number | null;
  cluster: ClusterRaw | null;
  consecutive_down_results: number;
  contact_details: Array<ContactDetailsRaw>;
  country_name: string;
  crawling_date: string;
  description: string;
  display_color: string;
  historical_price: Array<any>;
  id: Post.Id;
  images: Array<AssociatedImagePostFeed>;
  impact_score: number;
  infringement: boolean;
  infringement_type: string;
  infringing_account: null;
  is_desc_counterfeit: boolean;
  is_for_sale: boolean;
  is_recrawlable: boolean;
  is_product_used: boolean;
  last_activity: null | string;
  last_take_down_check_at: null | string;
  link: string | null;
  median_price: null | number;
  navee_score: null | number;
  organisation_currency_price: null | number;
  organisation_id: number;
  original_currency: null | string;
  original_price: null | number;
  moderation_reasons: Array<ModerationReasonRaw>;
  payload: null;
  poster: PosterPostFeed;
  posting_date: null | string;
  price_discrepancy: null | number;
  qa_checked: boolean | null;
  rank: null | number;
  stock_count: number;
  taken_down?: boolean | null;
  tags: Array<TagRaw>;
  title: string;
  translated_description: string;
  translated_title: string;
  validated: boolean | null;
  website: WebsitePostFeed | null;
}

export class PostFeedModel
  implements
    CanBeSelectable,
    CanBeModeratable,
    HasModerationPage,
    Magnifiable,
    TakenDownable
{
  moderation: Moderatable;

  id: Post.Id;

  archiveLink: string;

  website: Website | null;

  currentPrice: ItemPrice;

  medianPrice: ItemPrice;

  category: number | null;

  images: Array<AssociatedImage>;

  isForSale: boolean;

  moderationReason: Array<ModerationReason>;

  moderationReasonMapped: Map<
    SuspiciousPriority,
    Map<string, Array<ModerationReason>>
  >;

  crawlingDate: Dayjs;

  impactScore: number;

  naveeScore: number | null;

  poster: Poster;

  isRecrawlable: boolean;

  link: string;

  countryName: string;

  takenDown?: boolean | null;

  rank: number;

  text: Text;

  contacts: Array<Contact>;

  cluster?: Cluster;

  tags: Array<TagModel>;

  lastActivity: null | string;

  stockCount: number;

  private constructor(
    prop: Pick<
      PostFeedModel,
      | 'category'
      | 'tags'
      | 'stockCount'
      | 'archiveLink'
      | 'lastActivity'
      | 'cluster'
      | 'contacts'
      | 'text'
      | 'isForSale'
      | 'countryName'
      | 'rank'
      | 'link'
      | 'poster'
      | 'images'
      | 'naveeScore'
      | 'impactScore'
      | 'id'
      | 'isRecrawlable'
      | 'moderation'
      | 'takenDown'
      | 'website'
      | 'currentPrice'
      | 'medianPrice'
      | 'crawlingDate'
      | 'moderationReason'
    >,
  ) {
    this.id = prop.id;
    this.moderation = prop.moderation;
    this.website = prop.website;
    this.currentPrice = prop.currentPrice;
    this.medianPrice = prop.medianPrice;
    this.takenDown = prop.takenDown;
    this.crawlingDate = prop.crawlingDate;
    this.naveeScore = prop.naveeScore;
    this.isForSale = prop.isForSale;
    this.images = prop.images;
    this.poster = prop.poster;
    this.link = prop.link;
    this.rank = prop.rank;
    this.category = prop.category;
    this.countryName = prop.countryName;
    this.impactScore = prop.impactScore;
    this.isRecrawlable = prop.isRecrawlable;
    this.text = prop.text;
    this.contacts = prop.contacts;
    this.cluster = prop.cluster;
    this.lastActivity = prop.lastActivity;
    this.archiveLink = prop.archiveLink;
    this.stockCount = prop.stockCount;
    this.tags = prop.tags;
    this.moderationReason = prop.moderationReason;
    this.moderationReasonMapped = this.moderationReason.reduce(
      (acc, moderationReason) => {
        const reasons =
          acc.get(moderationReason.priority) ??
          new Map<string, Array<ModerationReason>>();
        const currentReasons =
          reasons.get(`${moderationReason.path}-${moderationReason.name}`) ??
          [];
        currentReasons.push(moderationReason);
        reasons.set(
          `${moderationReason.path}-${moderationReason.name}`,
          currentReasons,
        );
        acc.set(moderationReason.priority, reasons);
        return acc;
      },
      new Map<SuspiciousPriority, Map<string, Array<ModerationReason>>>(),
    );
  }

  get midSizeImage() {
    return this.images[0].url;
  }

  get key() {
    return this.id;
  }

  get queryParam() {
    return `post_id=${this.id}`;
  }

  toRecrawl() {
    return {
      label: this.moderation.moderation ?? '',
      url: this.link,
    };
  }

  get linkToModerationPage() {
    return `/post/${this.id}`;
  }

  get crawlingDateFormatted() {
    return this.crawlingDate.format(DATETIME_FORMAT_READABLE);
  }

  get posterUrl() {
    if (!this.poster?.id) {
      return '';
    }
    return `/account/${this.poster?.id}`;
  }

  get websiteUrl() {
    if (!this.website?.id) {
      return '';
    }
    return `/website/${this.website?.id}`;
  }

  get accountUrl() {
    return this.posterUrl || this.websiteUrl;
  }

  static createFromPostFeedRawModel(
    props: PostFeedRawModel,
    currencyIso: string,
  ): PostFeedModel {
    return new PostFeedModel({
      id: props.id,
      lastActivity: props.last_activity,
      archiveLink: props.archive_link,
      category: props.global_category_id,
      isForSale: props.is_for_sale,
      impactScore: props.impact_score,
      stockCount: props.stock_count,
      isRecrawlable: props.is_recrawlable,
      cluster: props.cluster
        ? new Cluster({
            contactInfo: props.cluster.contact_info,
            description: props.cluster.description,
            estimatedGeo: props.cluster.estimated_geo,
            id: props.cluster.id,
            imageLink: props.cluster.image_link,
            infringementPercentage: props.cluster.infringement_percentage,
            moderationPercentage: props.cluster.moderation_percentage,
            name: props.cluster.name,
            rank: props.cluster.rank,
            totalPosts: props.cluster.total_posts,
          })
        : undefined,
      text: new Text({
        description: props.description,
        descriptionTranslated: props.translated_description,
        title: props.title,
        titleTranslated: props.translated_title,
      }),
      contacts: (props.contact_details || []).map(
        Contact.createFromRawModelInverse,
      ),
      countryName: props.country_name,
      rank: props.rank || 0,
      link: props.link || '',
      moderationReason: (props.moderation_reasons || [])
        .map((reason) => ModerationReason.createReasonFromRaw(reason))
        .sort(moderationReasonSort),
      images: props.images.map(
        (image) =>
          new AssociatedImage({
            id: image.id,
            url: image.picture_url,
            moderation: new Moderatable(
              Object.assign(Moderatable.default, {
                moderationColor: image.duplicated_group.image_status_color,
                moderation: image.duplicated_group.image_status,
              } as Moderatable),
            ),
            duplicatedGroup: new AssociatedDuplicatedGroup({
              id: image.duplicated_group.id,
              occurrences: new Occurrences({
                account: 0,
                post: image.duplicated_group.occurrences,
                vendor: 0,
              }),
            }),
            tags: [],
          }),
      ),
      poster: new Poster({
        clusterInfringement: props.poster.cluster_infringement,
        followersCount: props.poster.followers_count,
        geo: props.poster.geo,
        id: props.poster.id,
        labelId: props.poster.label_id,
        localInfringement: props.poster.local_infringement || 0,
        priceDiscrepancy: props.price_discrepancy || 0,
        name: props.poster.name,
        numberOfModeratedPostsGlobally:
          props.poster.number_of_moderated_posts_globally,
        numberOfPosts: props.poster.number_of_posts,
        otherOrganisationsInfringement:
          props.poster.other_organisations_infringement,
        recidivism: props.poster.recidivism,
      }),
      moderation: new Moderatable({
        canEditModeration: props.can_edit_moderation,
        canEditValidation: props.can_edit_validation,
        canEditQaCheck: props.can_edit_qa_check,
        canChangeCategory: props.can_change_category,
        canViewQaCheck: props.can_view_qa_check,
        canEditReasons: props.can_edit_reasons,
        moderationMethod: null,
        canViewValidation: props.can_view_validation,
        validated: props.validated,
        moderation: props.infringement_type,
        moderationColor: props.display_color,
        qaChecked: props.qa_checked,
      }),
      website: props.website
        ? new Website({
            id: props.website.id,
            domain: props.website.domain_name,
            labelId: props.website.label_id,
            category: props.website.category_name,
          })
        : null,
      takenDown: props.taken_down,
      currentPrice: new ItemPrice({
        currency: currencyIso,
        price: props.organisation_currency_price,
      }),
      medianPrice: new ItemPrice({
        currency: currencyIso,
        price: props.median_price,
      }),
      crawlingDate: dayjs(props.crawling_date, 'ddd, DD MMM YYYY HH:mm:ss Z'),
      naveeScore: props.navee_score,
      tags: (props.tags || []).map(TagModel.createFromRawModel),
    });
  }
}
