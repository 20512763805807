/*
 *
 * FiltersBar actions
 *
 */

import { FetchableData } from 'product-types/src/common/FetchableData/FetchableData';
import { ModerationReason } from 'product-types/src/domain/moderationReason/ModerationReason';
import { UserLightModel } from 'product-types/src/domain/user/UserLightModel';
import { WebsiteCategory } from 'product-types/src/domain/website/WebsiteCategory';
import { TagModel, TagTypeEnum } from 'product-types/src/domain/tag/Tag';
import { WebsiteSuggestion } from 'product-types/src/domain/website/Website';
import { Label } from 'product-types/src/domain/label/Label';
import { FiltersContainer } from 'types/filters/MoleculesFilter/MolecileFilter';
import { SearchItemModel } from 'types/filters/AtomicFiltersImplementation/Search/SearchItem';
import { ProductCategoryTree } from 'product-types/src/domain/productCategory';
import { Insight } from 'product-types/src/domain/insight/insight';
import { AdvancedStatistics } from 'product-types/src/domain/advancedStats/AdvancedStats';
import { FilterPresets } from 'product-types/src/domain/globalData/GlobalData';
import { GeographySelectOptions } from 'product-types/src/domain/geo/Geo';
import { RoleModel } from 'product-types/src/domain/role/Role';
import { Currency } from 'product-types/src/domain/Currency';
import { ContactMainInfo } from 'product-types/src/domain/contact/Contact';
import { OrganisationStatus } from 'product-types/src/domain/organisation/OrganisationStatus';
import { LanguageModel } from 'product-types/src/domain/language/language';
import { CrawlingDomain } from 'product-types/src/domain/crawlingDomain';
import { ScrappingSource } from 'product-types/src/domain/scrappingSource/ScrappingSource';
import {
  SavedFilterRaw,
  SavedFilterType,
} from 'product-types/src/domain/savedFilters/SavedFilters';
import { Dispatch, SetStateAction } from 'react';
import { DeleteFilterData } from 'types/network/Http/productMonitor/endpoints/me/deleteFilter';
import { Filter } from 'types/filters/AtomicFilters/Filter';
import {
  SET_DEFAULT_DATE,
  DELETE_FILTER,
  LOAD_SAVED_FILTERS,
  LOAD_FILTERS_SUCCESS,
  SAVE_FILTER,
  SELECT_FILTER,
  SET_SHOW_ALL_POSTS_RESERVED_MODAL,
  RESET_STATE,
  SET_DASHBOARD_FILTER,
  SET_CLUSTERS_FILTER,
  LOAD_FILTERS_BAR_TAGS,
  UPDATE_FILTERS_BAR_POST_LABELS,
  SET_FEED_FILTERS,
  SET_UPLOAD_HISTORY_FILTERS,
  REMOVE_DASHBOARD_FILTER,
  REMOVE_CLUSTERS_FILTER,
  REMOVE_FEED_FILTER,
  REMOVE_UPLOAD_HISTORY_FILTERS,
  RESET_CLUSTERS_FILTER,
  RESET_FEED_FILTER,
  RESET_UPLOAD_HISTORY_FILTERS,
  READ_CLUSTERS_FILTERS,
  READ_FEED_FILTERS,
  READ_UPLOAD_HISTORY_FILTERS,
  REPLACE_FEED_FILTER,
  UPDATE_FILTERS_BAR_FEATURE_LABELS,
  LOAD_USERS,
  UPDATE_USERS,
  UPDATE_FILTERS_BAR_WEBSITE_CATEGORIES,
  UPDATE_FILTERS_BAR_TAGS,
  UPDATE_FILTERS_BAR_GEOGRAPHY,
  UPDATE_FILTERS_BAR_WEBSITES,
  UPDATE_FILTER_BAR_CATEGORIES,
  UPDATE_FILTERS_BAR_IMAGE_LABELS,
  UPDATE_FILTERS_BAR_ACCOUNT_LABELS,
  RESET_ALL_FILTERS,
  RESET_ALL_FILTERS_EXCEPT_ONE,
  READ_DASHBOARD_FILTERS,
  LOAD_IMAGE_FOR_SEARCH,
  UPDATE_IMAGE_FOR_SEARCH,
  LOAD_IMAGE_BY_FILE_FOR_SEARCH,
  LOAD_IMAGE_BY_ID_FOR_SEARCH,
  LOAD_SEARCH_ITEMS_DATA,
  ADD_LOADED_WEBSITES,
  LOAD_GLOBAL_DATA,
  UPDATE_HIDE_MODERATION_STATISTICS,
  UPDATE_INSIGHTS,
  UPDATE_ADVANCED_STATISTICS,
  LOAD_ADVANCED_STATISTICS,
  UPDATE_ROLES,
  UPDATE_CURRENCIES,
  UPDATE_FILTERS_BAR_AVAILABLE_CONTACT_TYPES,
  SET_ORGANISATION_STATUSES,
  UPDATE_LANGUAGES,
  UPDATE_FILTERS_BAR_CRAWLING_DOMAINS,
  UPDATE_SCRAPPING_SOURCES,
} from './constants';

export function resetState() {
  return {
    type: RESET_STATE,
  };
}

export function updateLabelsFilterBar(labels: FetchableData<Array<Label>>) {
  return {
    type: UPDATE_FILTERS_BAR_POST_LABELS,
    labels,
  };
}

export function updateAccountLabelsFilterBar(accountLabels) {
  return {
    type: UPDATE_FILTERS_BAR_ACCOUNT_LABELS,
    accountLabels,
  };
}

export function updateImageLabelsFilterBar(imageLabels) {
  return {
    type: UPDATE_FILTERS_BAR_IMAGE_LABELS,
    imageLabels,
  };
}

export function updateHideModerationStatistics(value: boolean) {
  return {
    type: UPDATE_HIDE_MODERATION_STATISTICS,
    value,
  };
}

export function updateCategoriesFilterBar(
  categories: FetchableData<ProductCategoryTree>,
) {
  return {
    type: UPDATE_FILTER_BAR_CATEGORIES,
    categories,
  };
}

export const setDefaultDateAction = (interval) => ({
  type: SET_DEFAULT_DATE,
  interval,
});

export function loadUsers() {
  return {
    type: LOAD_USERS,
  };
}

export function resetAllFilters() {
  return {
    type: RESET_ALL_FILTERS,
  };
}

export function resetAllFiltersExceptOne(except = 'feed') {
  return {
    type: RESET_ALL_FILTERS_EXCEPT_ONE,
    except,
  };
}

export function updateUsers(users: FetchableData<Array<UserLightModel>>) {
  return {
    type: UPDATE_USERS,
    users,
  };
}

export function updateRoles(roles: FetchableData<Array<RoleModel>>) {
  return {
    type: UPDATE_ROLES,
    roles,
  };
}

export function loadFilterSuccessAction(filters: FilterPresets) {
  return {
    type: LOAD_FILTERS_SUCCESS,
    filters,
  };
}

export function loadFiltersAction() {
  return {
    type: LOAD_SAVED_FILTERS,
  };
}

export function setOrganisationStatuses(
  organisationStatuses: FetchableData<Array<OrganisationStatus>>,
) {
  return {
    type: SET_ORGANISATION_STATUSES,
    organisationStatuses,
  };
}

export function updateFeatureLabelesOptions(
  featureLabels: FetchableData<Array<ModerationReason>>,
) {
  return {
    type: UPDATE_FILTERS_BAR_FEATURE_LABELS,
    featureLabels,
  };
}

export function saveFilterAction(
  data: SavedFilterRaw,
  setErrorMessage: Dispatch<SetStateAction<string>>,
  setShowSaveFilterModal: Dispatch<SetStateAction<boolean>>,
  filter_type: SavedFilterType,
) {
  return {
    type: SAVE_FILTER,
    data,
    setShowSaveFilterModal,
    setErrorMessage,
    filter_type,
  };
}

export function deleteFiltersAction(data: DeleteFilterData, filter_type) {
  return {
    type: DELETE_FILTER,
    data,
    filter_type,
  };
}

export function setShowAllPostsReservedAction(
  show,
  modal_text = '',
  modal_title = 'No posts available',
) {
  return {
    type: SET_SHOW_ALL_POSTS_RESERVED_MODAL,
    show,
    modal_text,
    modal_title,
  };
}

export function selectFilterAction(field, value, type = SELECT_FILTER) {
  return {
    type,
    field,
    value,
  };
}

// TODO
// LOAD ACTIONS FOR EACH SLICE ACTUALY UNNECESSARY
// WE SHOULD USE ONE LOADER FOR EACH DATA FOR ALL SLICES BECAUSE THEY ARE SAME
// AFTER END OF SLICING WE SHOULD WRITE ONE RECURSIVE REDUCER UPDATER AND USE ONLY JUST LOADER ACTIONS CREATED ABOVE

// DASHBOARD FILTERS ACTIONS

export function setDashboardFilterAction(updates, type = SET_DASHBOARD_FILTER) {
  return {
    type,
    updates,
  };
}
export function removeDashboardFilterAction(
  updates,
  type = REMOVE_DASHBOARD_FILTER,
) {
  return {
    type,
    updates,
  };
}

// CLUSTERS FILTERS ACTIONS
export function setClustersFilterAction(updates, type = SET_CLUSTERS_FILTER) {
  return {
    type,
    updates,
  };
}

export function removeClustersFilterAction(
  updates,
  type = REMOVE_CLUSTERS_FILTER,
) {
  return {
    type,
    updates,
  };
}

export function resetClustersFilter() {
  return {
    type: RESET_CLUSTERS_FILTER,
  };
}

export function readClustersFilter() {
  return {
    type: READ_CLUSTERS_FILTERS,
  };
}

export function readDashboardFilter() {
  return {
    type: READ_DASHBOARD_FILTERS,
  };
}

// FEED FILTERS ACTIONS

export function setFeedFilterAction(updates: Filter, hasChanged?: boolean) {
  return {
    type: SET_FEED_FILTERS,
    updates,
    hasChanged,
  };
}

export function resetFeedFilter(
  currentView: 'image' | 'post' | 'account' | 'website',
  applyDefault = false,
) {
  return {
    type: RESET_FEED_FILTER,
    currentView,
    applyDefault,
  };
}

export function replaceFeedFilter(updates: { feed: FiltersContainer }) {
  return {
    type: REPLACE_FEED_FILTER,
    updates,
  };
}

export function readFeedFilter() {
  return {
    type: READ_FEED_FILTERS,
  };
}

export function updateAvailableContactTypes(
  availableContactTypes: FetchableData<Array<ContactMainInfo>>,
) {
  return {
    type: UPDATE_FILTERS_BAR_AVAILABLE_CONTACT_TYPES,
    availableContactTypes,
  };
}

export function updateCrawlingDomain(
  crawlingDomains: FetchableData<Array<CrawlingDomain>>,
) {
  return {
    type: UPDATE_FILTERS_BAR_CRAWLING_DOMAINS,
    crawlingDomains,
  };
}

export function removeFeedFilterAction(updates, type = REMOVE_FEED_FILTER) {
  return {
    type,
    updates,
  };
}

export function loadSearchItemsData({
  filter,
  abortController,
}: {
  filter: FiltersContainer;
  abortController: AbortController;
}) {
  return {
    type: LOAD_SEARCH_ITEMS_DATA,
    filter,
    abortController,
  };
}

// UPLOAD HISTORY ACTIONS
export function setUploadHistoryFiltersAction(
  updates,
  type = SET_UPLOAD_HISTORY_FILTERS,
) {
  return {
    type,
    updates,
  };
}

export function removeUploadHistoryFiltersAction(
  updates,
  type = REMOVE_UPLOAD_HISTORY_FILTERS,
) {
  return {
    type,
    updates,
  };
}

export function resetUploadHistoryFiltersAction() {
  return {
    type: RESET_UPLOAD_HISTORY_FILTERS,
  };
}

export function readUploadHistoryFilter() {
  return {
    type: READ_UPLOAD_HISTORY_FILTERS,
  };
}

// LOADING DATA CONTANTS ACTIONS

export function loadFiltersBarTagsAction(tagType: TagTypeEnum) {
  return {
    type: LOAD_FILTERS_BAR_TAGS,
    tagType,
  };
}

export function updateFilterBarTags(
  tagType: TagTypeEnum,
  tags: FetchableData<Array<TagModel>>,
) {
  return {
    type: UPDATE_FILTERS_BAR_TAGS,
    tagType,
    tags,
  };
}

export function updateFiltersBarWebsiteCategoriesAction(
  availableWebsiteCategories: FetchableData<Array<WebsiteCategory>>,
) {
  return {
    type: UPDATE_FILTERS_BAR_WEBSITE_CATEGORIES,
    availableWebsiteCategories,
  };
}

export function updateAvailableLanguages(
  availableLanguages: FetchableData<Array<LanguageModel>>,
) {
  return {
    type: UPDATE_LANGUAGES,
    availableLanguages,
  };
}

export function updateScrappingSource(
  scrappingSource: FetchableData<Array<ScrappingSource>>,
) {
  return {
    type: UPDATE_SCRAPPING_SOURCES,
    scrappingSource,
  };
}

export function updateFiltersBarGeography(
  geographySelectOptions: FetchableData<GeographySelectOptions>,
) {
  return {
    type: UPDATE_FILTERS_BAR_GEOGRAPHY,
    geographySelectOptions,
  };
}

export function updateInsights(insightOptions: FetchableData<Array<Insight>>) {
  return {
    type: UPDATE_INSIGHTS,
    insightOptions,
  };
}

export function updateAdvancedStatistics(
  advancedStatistics: FetchableData<AdvancedStatistics>,
) {
  return {
    type: UPDATE_ADVANCED_STATISTICS,
    advancedStatistics,
  };
}

export function loadAdvancedStatistics() {
  return {
    type: LOAD_ADVANCED_STATISTICS,
  };
}

export function loadGlobalDataAction() {
  return {
    type: LOAD_GLOBAL_DATA,
  };
}

export function appendNonTrackedWebsites({
  websites,
}: {
  websites: Array<WebsiteSuggestion>;
}) {
  return {
    type: ADD_LOADED_WEBSITES,
    websites,
  };
}

export function updateFiltersBarWebsites(
  websites: FetchableData<Array<WebsiteSuggestion>>,
) {
  return {
    type: UPDATE_FILTERS_BAR_WEBSITES,
    websites,
  };
}

export function updateCurrencies(currencies: FetchableData<Array<Currency>>) {
  return {
    type: UPDATE_CURRENCIES,
    currencies,
  };
}

export interface LoadImageForSearchProps {
  searchItem: SearchItemModel;
  searchFilterUUID: string;
  abortController?: AbortController;
}

export const loadImageForSearch = ({
  searchItem,
  searchFilterUUID,
}: LoadImageForSearchProps) => ({
  type: LOAD_IMAGE_FOR_SEARCH,
  searchItem,
  searchFilterUUID,
});

export const loadImageByFileForSearch = ({
  searchItem,
  searchFilterUUID,
}: LoadImageForSearchProps) => ({
  type: LOAD_IMAGE_BY_FILE_FOR_SEARCH,
  searchItem,
  searchFilterUUID,
});

export const loadImageByIdForSearch = ({
  searchItem,
  searchFilterUUID,
  abortController,
}: LoadImageForSearchProps) => ({
  type: LOAD_IMAGE_BY_ID_FOR_SEARCH,
  searchItem,
  abortController,
  searchFilterUUID,
});

export const updateImageForSearch = ({
  searchItem,
  searchFilterUUID,
}: LoadImageForSearchProps) => ({
  type: UPDATE_IMAGE_FOR_SEARCH,
  searchItem,
  searchFilterUUID,
});
